import React from 'react'

function PrincipalsMessageDesign() {
  return (
    <div><>
  
    <div className="container mt-5 mb-5">
      <div className="row register" style={{ maxWidth: "100%" }}>
        <div className="col-md-3">
        <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1706163079559.32"
              alt=""
              className="img-responsive img-thumbnail"
              width={350}
              height={260}
            />
            <br />
            <br />
            
            <h4>
              Prof. (Dr)  Maulik Shah
              <br />
            </h4>
            <p style={{  }}>
            Dean - Faculty of Design Swaminaryan University  <br />
            Dean – students Welfare  <br />
          </p>
        </div>
        <div className="col-md-9">
          <h3>
            PRINCIPAL’S MESSAGE
            <br />
          </h3>
          <p style={{ textAlign: "justify" }}>
            Welcome to the Swaminaryan University Design Institute!
          </p>
          <p style={{ textAlign: "justify" }}>
          As the Dean of the Faculty of Design, it is my pleasure to extend a warm welcome to all aspiring designers who are passionate about transforming their creative visions into reality. Here at Swaminaryan University, we believe in fostering a culture of innovation, exploration, and excellence in design education.
          </p>
          <p style={{ textAlign: "justify" }}>
          Our team is dedicated to providing students with a comprehensive education that combines artistic expression with practical skills and industry knowledge. Whether you dream of designing captivating graphics, crafting stylish apparels or shaping innovative products, our programs are designed to equip you with the tools and expertise needed to succeed in today's competitive design industry.
          </p>
          <p style={{ textAlign: "justify" }}>
          At Swaminaryan University Faculty of Design, you will have the opportunity to learn from experienced faculty members who are not only experts in their respective fields but also passionate mentors committed to helping you unleash your full creative potential. Our state-of-the-art facilities and collaborative learning environment provide the perfect backdrop for you to explore new ideas, experiment with different techniques, and cultivate your unique design style.
          </p>
          <p style={{ textAlign: "justify" }}>
          We believe that diversity fuels creativity, and we are proud to welcome students from all backgrounds and walks of life to our institute. Whether you're a seasoned artist or a newcomer to the world of design, you'll find a supportive community of fellow designers who share your passion and enthusiasm for creativity.
          </p>
          <p style={{ textAlign: "justify" }}>
          As Dean, my goal is to ensure that every student who walks through our doors receives a world-class education that prepares them for success in their chosen field. Our commitment to excellence extends beyond the classroom, as we provide opportunities for internships, industry collaborations, and real-world projects that allow students to gain practical experience and build valuable connections within the design industry.
          </p>
          <p style={{ textAlign: "justify" }}>
          I invite you to explore all that Swaminaryan University Faculty of Design has to offer and discover how we can help you turn your passion for design into a fulfilling and rewarding career. Whether you're interested in pursuing a degree in graphic design, fashion design, interior design, or industrial design, we are here to support you every step of the way.
          </p>
          <p style={{ textAlign: "justify" }}>
          Thank you for considering Swaminaryan University Faculty of Design for your design education journey. We look forward to welcoming you to our vibrant and creative community!
          </p>
          <br/>
          <p><h6>Warm regards,</h6>
             Prof. (Dr)  Maulik Shah <br/>
             Dean - Faculty of Design Swaminaryan University <br/>
             Dean – students Welfare
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>{" "}
    
  </>
  </div>
  )
}

export default PrincipalsMessageDesign;
