import React from 'react'

function AboutCollegePharmacy() {
  return (
    <div><>
    {/* Start For Content */}
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-3">
        
            <img
              // src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637632899_2902-org.jpg"
              src="/assets/pharmacy/images/banner-2.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={300}
              height={600}
            />
            <br />
            <br />

       
       
        </div>
        <div className="col-md-9">
          <h2>
          About College
            <br />
          </h2>
 
          <p style={{ textAlign: "justify" }}>
            A pharmacy college serves as an essential hub for nurturing future pharmacists, who play a crucial role in 
            healthcare systems worldwide. Established in 2008, Shree Swaminarayan College of Pharmacy, Kalol is 
            a leading institute in higher education, located in Kalol, Gujarat. Within its walls, students can delve 
            their education from the institute in Degree courses including UG, PG and Doctorate programs. These programs 
            are offered in Full-Time mode. The institute has a good reputation for courses such as Pharm.D, M. Pharma, B.Pharma &. 
            The courses provided are in the stream of Medicine & Health Sciences and are approved by the Pharmacy Council of 
            India, New Delhi. Practical activities, like lab work and internships, supplement classroom instruction to make 
            sure students learn both theoretical and practical abilities. Beyond academics, our college cultivate a sense 
            of community, encouraging collaboration among peers and fostering professional networks. Ultimately, we serve as 
            incubators for the next generation of pharmacists, equipped to make meaningful contributions to healthcare 
            and improve the quality of life for countless individuals.
          </p>


          {/* <p style={{ textAlign: "justify" }}>
      
            With the blessings of Shree Swami Premswarupdasji Maharaj “Shree Swaminarayan Pharmacy College” came into the existence in the year of 2017. Our Pharmacy College aims to be a leading institution of Pharmaceutical education and research, recognized for its commitment to academic excellence, cutting-edge discoveries, and the development of critical thinking and problem-solving skills. We strive to cultivate a culture of collaboration, and ethical conduct, empowering our students to make meaningful contributions to Pharmaceutical knowledge and address the pressing challenges of our time.Students are trained and gets hands-on- experience in such away that they readily fit into industry.
            We update curriculum to meet the current industry needs with the inputs from industry and academic experts.The College has vibrant clusters of innovation ecosystems focused on drug discovery and development; in collaboration with industrial partners and research organizations.
          </p> */}
          
        </div>
      </div>
      <br />
      <br />
    </div>{" "}
    {/* End For Content */}
    
  </>
  </div>
  )
}

export default AboutCollegePharmacy;