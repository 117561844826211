import React from 'react'

function PhdSubjects() {
  return (
    <div className='PhdSubjects'>
        <>
  <h1 className="text-center">
    Ph.D Subjects &amp; Admissions 2023-24 (Batch-1)
  </h1>
  <div className="academics-area pt-100 pb-70">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="academics-left-content">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="flaticon-heartbeat" />
                  </div>
                  <a href="#">
                    <h3>Ayurveda</h3>
                  </a>
                  <p>
                    Samhita &amp; Siddhanta, Roga Nidan, Kayachikitsa,
                    Shalyatantra, Rasa Shastra &amp; Bhaishajya Kalpana, Prasuti
                    Tantra &amp; Stree Roga
                  </p>
                  <p>Fees: Rs.60,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="ri-first-aid-kit-line" />
                  </div>
                  <a href="#">
                    <h3>Homoeopathy</h3>
                  </a>
                  <p>Practice of Medicine, Repertory, Organon of Medicine,Materia Medica, Pharmacy</p>
                  <p>Fees: Rs.60,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="flaticon-help" />
                  </div>
                  <a href="#">
                    <h3>Science</h3>
                  </a>
                  <p>
                    Botany, Maths, Microbiology, Zoology, Chemistry
                  </p>
                  <p>Fees: Rs.30,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="flaticon-pc" />
                  </div>
                  <a href="#">
                    <h3>Engineering</h3>
                  </a>
                  <p> Computer Science &amp; Applications</p>
                  <p>Fees: Rs.30,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              {/* <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="flaticon-auction" />
                  </div>
                  <a href="#">
                    <h3>Law</h3>
                  </a>
                  <p>Law Department</p>
                  <p>Fees: Rs.30,000/Semester</p>
                </div>
              </div> */}
               <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="ri-font-size" />
                  </div>
                  <a href="#">
                    <h3>English</h3>
                  </a>
               
                  <p></p>
                </div>
              </div>
              
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="flaticon-blueprint" />
                  </div>
                  <a href="#">
                    <h3>Education</h3>
                  </a>
                  <p>Education</p>
                  <p>Fees: Rs.30,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="ri-capsule-line" />
                  </div>
                  <a href="#">
                    <h3>Pharmacy</h3>
                  </a>
                  <p>Pharmaceutics</p>
                  <p>Fees: Rs.30,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i className="ri-font-size" />
                  </div>
                  <a href="#">
                    <h3>Sanskrit</h3>
                  </a>
                  <p>Sanskrit</p>
                  <p>Fees: Rs.15,000/Semester</p>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                  <i class="ri-hospital-fill"></i>
                  </div>
                  <a href="#">
                    <h3>Medicine</h3>
                    <p>Microbiology & Pathology</p>
                  </a>
                 
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                  <i class="ri-empathize-line"></i>
                  </div>
                  <a href="#">
                    <h3>Medical Anatomy</h3>
                  </a>
                 
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                  <i class="ri-stethoscope-line"></i>
                  </div>
                  <a href="#">
                    <h3>Medical Physiology</h3>

                 
                  </a>
                 
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                  <i class="ri-microscope-line"></i>
                  </div>
                  <a href="#">
                    <h3>Medical Biochemistry</h3>
                  </a>
                 
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-academics-card3">
                  <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/list+of+disciplins+%26+subjects+-27-4-23.pdf" target="_blank">
                    <h3>LIST OF DISCIPLINES In Ph. D Allied subjects</h3>
                  </a>
                  {/*<a href="academics-details.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default PhdSubjects