import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate } from "react-router-dom";
import "./sciencehome.css";
import bsc_programmes from "./bsc_programmes";
import msc_programmes from "./msc_programmes";
import { ADD_SLIDERS } from "../../Utils/InfoUploadingConst";
import "../Home/Home.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home() {
  const banner1 =
    "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/images/DJI_0389.JPG";
  const banner2 =
    "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/images/DJI_0373.JPG";
  const banner3 =
    "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/images/DJI_0449.JPG";

  const [info, setInfo] = useState([]);

  // State for managing active slide index
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_SLIDERS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id == 1111011
        );
        console.log(activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const PopUpData = info?.filter((element) => element.type == "PopUp");
  // console.log("PopUpData -", PopUpData);

  // Start automatic sliding when component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment active slide index
      setActiveSlideIndex((prevIndex) =>
        prevIndex === info.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Change slide every 7 seconds (adjust as needed)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [info.length]); // Trigger effect when info length changes

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          {/* PopUp */}
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              // className="modal-dialog modal-xl"
              className="modal-dialog modal-lg modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel" />
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <Swiper
                      modules={[Autoplay, Pagination, Navigation]}
                      autoplay={{ delay: 5000, disableOnInteraction: false }} // Autoplay with 7-second delay
                      slidesPerView={1}
                      navigation
                      pagination={{ clickable: true }}
                      onSlideChange={(swiper) =>
                        setActiveSlideIndex(swiper.realIndex)
                      }
                    >
                      {info
                        .filter((item) => item.type == "PopUp")
                        .map((item, index) => (
                          <SwiperSlide key={index}>
                            <h3 className="text-center blink_me d-none d-sm-block">
                              {item.title}
                            </h3>

                            <img
                              src={item.image.replace(/"/g, "")}
                              className="d-block w-100 img-fluid PopUp"
                              alt="..."
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* sliders images */}
          <div className="banner-area">
            <Swiper
              // Add Autoplay module and set autoplay options
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{ delay: 7000, disableOnInteraction: false }} // Autoplay with 7-second delay
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
            >
              {/* Map through info to create swiper slides */}
              {info
                .filter((item) => item.type == "Main Slider")
                .map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item.image.replace(/"/g, "")}
                      className="d-block w-100 img-fluid h-80 main-slider"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h1 className="text-light">{item.title}</h1>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        <div className="campus-information-area pb-70 mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 aos-init aos-animate"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration={1300}
                data-aos-once="true"
              >
                <div className="campus-content pr-20">
                  <div className="campus-title">
                    <h2>Campus Information</h2>
                    <p>
                      Shree Swaminarayan Science College is an esteemed
                      educational establishment dedicated to the delivery of
                      education and training in diverse scientific fields. This
                      institution places a significant emphasis on fostering
                      academic excellence and research in the realms of
                      Chemistry, Microbiology, Botany, Zoology, Biotechnology,
                      Physics, Mathematics, and Environmental Science. Through
                      its comprehensive academic offerings, this college
                      provides an extensive array of educational opportunities
                      for students, spanning from undergraduate programs to
                      postgraduate studies and even opportunities for advanced
                      research through Ph.D. programs.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration={1300}
                data-aos-once="true"
              >
                <div className="campus-image pl-20">
                  <img
                    // src={banner1}
                    src="/assets/physiotherapy/Campus Image.jpg"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="course-section">
          <div class="container">
            <div className="section-title">
              <h2>OFFERED PROGRAM</h2>
            </div>
            <div className="sub-section-title">
              <h2>B.Sc (Honours) Programmes</h2>
            </div>
            <div class="row mbr-justify-content-center">
              {bsc_programmes.map((subject) => (
                <div class="col-lg-6 mbr-col-md-10">
                  <Link to={`Syllabus/${subject.link}`}>
                    <div class="wrap">
                      <div class="ico-wrap">
                        <span
                          class="mbr-iconfont fa fa-graduation-cap"
                          style={{ color: "#fff" }}
                        ></span>
                      </div>
                      <div class="text-wrap vcenter">
                        <h2
                          class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"
                          style={{ color: "#fff" }}
                        >
                          {subject.name}
                        </h2>
                        <p
                          class="mbr-fonts-style text1 mbr-text display-6"
                          style={{ color: "#fff" }}
                        >
                          To get more details on {subject.name}'s Syllabus
                          details , Click here
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section> */}

        {/* <section className="course-section">
          <div class="container">
            <div className="sub-section-title">
              <h2>M.Sc Programmes</h2>
            </div>
            <div class="row mbr-justify-content-center">
              {msc_programmes.map((subject) => (
                <div class="col-lg-6 mbr-col-md-10">
                  <Link to={`Syllabus/${subject.link}`}>
                    <div class="wrap">
                      <div class="ico-wrap">
                        <span
                          class="mbr-iconfont fa fa-graduation-cap"
                          style={{ color: "#fff" }}
                        ></span>
                      </div>
                      <div class="text-wrap vcenter">
                        <h2
                          class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"
                          style={{ color: "#fff" }}
                        >
                          {subject.name}
                        </h2>
                        <p
                          class="mbr-fonts-style text1 mbr-text display-6"
                          style={{ color: "#fff" }}
                        >
                          To get more details on {subject.name}'s Syllabus
                          details , Click here
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section> */}
      </div>
      <br />

      <div className=" description container mt-4">
        <h1 className="text-center">DEPARTMENTS</h1>
        <br />

        <div
          className="nav nav-tabs d-flex flex-start mt-2"
          id="nav-tab"
          role="tablist"
        >
          <button
            className="nav-link active"
            id="nav-instructor-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-instructor"
            type="button"
            role="tab"
            aria-controls="nav-instructor"
            aria-selected="false"
            tabIndex={-1}
          >
            Undergraduate
          </button>
          <button
            className="nav-link"
            id="nav-postgraduate-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-postgraduate"
            type="button"
            role="tab"
            aria-controls="nav-postgraduate"
            aria-selected="false"
            tabIndex={-1}
          >
            Postgraduate / Master
          </button>
        </div>

        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade active show"
            id="nav-instructor"
            role="tabpanel"
            aria-labelledby="nav-instructor-tab"
          >
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.Sc. Zoology</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.Sc. Chemistry</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.Sc. Biotechnology</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.Sc. Botany</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.Sc. Physics</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.sc. Maths</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>B.sc. Microbiology</h3>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade "
            id="nav-postgraduate"
            role="tabpanel"
            aria-labelledby="nav-postgraduate-tab"
          >
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Chemistry</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Microbiology</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Botany</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Zoology</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Mathematics</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Biotechnology</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-academics-card3">
                  <div className="icon">
                    <i class="ri-nurse-line"></i>
                  </div>

                  <h3>Department of Physics</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <h1 className="text-center mb-5">Campus Life</h1>

      <div className="row">
        <div className="d-flex justify-content-center align-items-center flex-wrap ">
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/campus.PNG"
              className="img-fluid icons mb-3"
              width="150"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">LUSH GREEN CAMPUS </h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/clasroom.PNG"
              className="img-fluid icons mb-3"
              width="70"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">DIGITAL CLASSROOM </h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/library+%26+labs.PNG"
              className
              icons="img-fluid mb-3"
              width="100"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">LIBRARY & LABS </h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sports.PNG"
              className="img-fluid icons mb-3"
              width="100"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">SPORTS GROUND</h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/hostel.PNG"
              className="img-fluid icons mb-3"
              width="100"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">HOSTEL FACILITY</h3>
          </div>

          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/bus.PNG"
              className="img-fluid icons mb-3"
              width="100"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">TRANSPORT FACILITY</h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/placement.PNG"
              className="img-fluid icons mb-3"
              width="90"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">TRAINING AND PLACEMENT</h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/canteen.PNG"
              className="img-fluid icons mb-3"
              width="140"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">CAFETERIA</h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/wifi.PNG"
              className="img-fluid icons mb-4"
              width="120"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">WIFI CAMPUS</h3>
          </div>
          <div
            className="text-center mx-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/gym.PNG"
              className="img-fluid icons mb-3"
              width="80"
              alt=""
              srcset=""
            />{" "}
            <br />
            <h3 className="mb-5">GYM</h3>
          </div>
        </div>
      </div>

      <div className="academic-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>College Feature</h2>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration={1200}
              data-aos-delay={200}
              data-aos-once="true"
            >
              <div className="single-academics-card2 bg-1">
                <div className="serial">
                  <p>01.</p>
                </div>
                <div className="academic-content">
                  <div className="academic-top-content">
                    <i className="flaticon-graduation" />
                    <a href="#">
                      <h3>PHOTO GALLERY</h3>
                    </a>
                  </div>
                  <p>
                    Glimpses of some of the events and campus infrastructure
                  </p>
                  <a href="#" className="read-more-btn white-color">
                    View More
                    <i className="flaticon-next" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration={1200}
              data-aos-delay={400}
              data-aos-once="true"
            >
              <div className="single-academics-card2 bg-3">
                <div className="serial">
                  <p>02.</p>
                </div>
                <div className="academic-content">
                  <div className="academic-top-content">
                    <i className="flaticon-graduation" />
                    <a href="#">
                      <h3>STUDENT CORNER</h3>
                    </a>
                  </div>
                  <p>
                    Student login space for progress reports, attendance and
                    more
                  </p>
                  <a href="#" className="read-more-btn white-color">
                    Login
                    <i className="flaticon-next" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {PopUpData && PopUpData?.length > 0 && (
        <Helmet>
          <script type="text/javascript">
            $('#exampleModal').modal('show');
          </script>
        </Helmet>
      )}
    </div>
  );
}

export default Home;
