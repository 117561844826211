import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./Swaminarayan.scss";

const url =
  "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/SANKALP-VISION.pdf";

export default function Test() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div className="SankalpVision">
      <div className="faq-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <h2 className="text-center">SANKALP VISION</h2>
            <div className="col-lg-12">
              {/* <div className="faq-image"> */}
              <hr />
              <object
                height={1000}
                data="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/SANKALP-VISION.pdf#toolbar=0"
                type="application/pdf"
                width="100%"
              >
                <p>
                  It appears you don't have a PDF plugin for this browser. No
                  biggie... you can{" "}
                  <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/SANKALP-VISION.pdf#toolbar=0">
                    click here to download the PDF file.
                  </a>
                </p>
              </object>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="main container">
	<Document
		file={url}
		onLoadSuccess={onDocumentLoadSuccess}
	>
		<Page pageNumber={pageNumber} />
	</Document>
	<div className='container'>
		<div className="pagec my-2">
		Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
		</div>
		<div className="buttonc d-flex justify-content-between">
		<button
		type="button"
		disabled={pageNumber <= 1}
		onClick={previousPage}
		className="Pre icon "
			
		>
		<i class="ri-arrow-left-s-line"></i>
		</button>
		<button
		type="button"
		disabled={pageNumber >= numPages}
		onClick={nextPage}
		className='icon'
		>
		<i class="ri-arrow-right-s-line"></i>
		</button>
		</div>
	</div>
	</div> */}
    </div>
  );
}
