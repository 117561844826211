import React from 'react'
import './style.scss'

function AdmissionForm() {
  return (
    <div className='AdmissionForm'>
        <div className="courses-details-area pt-100 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="courses-details">
          <div className="courses-card">
            <h2>Admission   Forms and Notification</h2>
          </div>
          <div className="description">
            <div className="container p-0">
              <nav>
                <div
                  className="nav nav-tabs d-flex justify-content-between"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    className="nav-link "
                    id="nav-bams-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bams"
                    type="button"
                    role="tab"
                    aria-controls="nav-bams"
                    aria-selected="true"
                  >
                    Ayurvedic
                  </button>
                  <button
                    className="nav-link"
                    id="nav-bhms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bhms"
                    type="button"
                    role="tab"
                    aria-controls="nav-bhms"
                    aria-selected="false"
                  >
                    Homoeopathy 
                  </button>
                  <button
                    className="nav-link"
                    id="nav-engineer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-engineer"
                    type="button"
                    role="tab"
                    aria-controls="nav-engineer"
                    aria-selected="false"
                  >
                    Engineering
                  </button>
                  <button
                    className="nav-link active"
                    id="nav-nursing-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-nursing"
                    type="button"
                    role="tab"
                    aria-controls="nav-nursing"
                    aria-selected="false"
                  >
                    Nursing <span className="blink text-warning"> New</span>
                      
                  </button>
                  <button
                    className="nav-link"
                    id="nav-law-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-law"
                    type="button"
                    role="tab"
                    aria-controls="nav-law"
                    aria-selected="false"
                  >
                    LAW
                  </button>
                  <button
                    className="nav-link"
                    id="nav-pharmacy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-pharmacy"
                    type="button"
                    role="tab"
                    aria-controls="nav-pharmacy"
                    aria-selected="false"
                  >
                    Pharmacy
                    
                  </button>
                  <button
                    className="nav-link"
                    id="nav-science-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-science"
                    type="button"
                    role="tab"
                    aria-controls="nav-science"
                    aria-selected="false"
                  >
                    Science
                  </button>
                  <button
                    className="nav-link"
                    id="nav-educate-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-educate"
                    type="button"
                    role="tab"
                    aria-controls="nav-educate"
                    aria-selected="false"
                  >
                    B.Ed
                  </button>
                  <button
                    className="nav-link"
                    id="nav-commerce-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-commerce"
                    type="button"
                    role="tab"
                    aria-controls="nav-ugc"
                    aria-selected="false"
                  >
                    Commerce{" "}
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">

              <div
                        className="tab-pane fade"
                        id="nav-commerce"
                        role="tabpanel"
                        aria-labelledby="nav-commerce-tab"
                      >
                        <div className="row align-items-center">
                         <div className="col-lg-12 table-responsive">
                            
                  <h2>Yet to be released!</h2>
                  <hr />
                           
                          </div>
                        </div>
                      </div>
                <div
                  className="tab-pane fade "
                  id="nav-bams"
                  role="tabpanel"
                  aria-labelledby="nav-bams-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="faq-image pr-20">
                        <h2>Yet to be released!</h2>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-bhms"
                  role="tabpanel"
                  aria-labelledby="nav-bhms-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="faq-image pr-20">
                      <div className="row align-items-center">
                        <div className="col-lg-12 table-responsive">
                           
                  <h2>Yet to be released!</h2>
                  <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-engineer"
                  role="tabpanel"
                  aria-labelledby="nav-engineer-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="faq-image pr-20">
                        <h2>Yet to be released</h2>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="nav-nursing"
                  role="tabpanel"
                  aria-labelledby="nav-nursing-tab"
                >
                    <div className="row">
                        <div className="col-md-12">
                        <table className="table notification">
                                <thead>
                                    <tr>
                                        <th width="30%">
                                            Date
                                        </th>
                                        <th>Notification</th>
                                        
                                    </tr>
                                    
                                </thead>
                                    <tbody>
                                    <tr>
                                            <td>03.06.2023</td>
                                    <td> <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Admissions/ADMISSION+FORM+M.Sc+NURSING+(3).pdf" target="_blank">MSc. Nursing Admission Form </a></td>
                                        </tr>
                                        <tr>
                                            <td>03.06.2023</td>
                                    <td> <a href=" https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Admissions/ADMISSION+FORM+POST+BASIC.pdf" target="_blank">Post Basic Admission Form </a></td>
                                        </tr>
                                        <tr>
                                            <td>03.06.2023</td>
                                    <td> <a href=" https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Admissions/POST+BASIC+%26+M.SC+N+ADMISSION.pdf" target="_blank">MSc. Nursing  and Post Basic Notification </a></td>
                                        </tr>
                                       
                                   
                                        
                                    </tbody>
                            </table>
                        </div>
                    </div>
                
                
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-pharmacy"
                  role="tabpanel"
                  aria-labelledby="nav-pharmacy-tab"
                >
               
                  
                  <h2>Yet to be released!</h2>
                  <hr />
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-science"
                  role="tabpanel"
                  aria-labelledby="nav-science-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="faq-image pr-20">
                       <div className="row">
                        <div className="col md-12 table-responsive">
                        <h2>Yet to be released!</h2>
                  <hr />
                        </div>
                       </div>
                       
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-educate"
                  role="tabpanel"
                  aria-labelledby="nav-educate-tab"
                >
               <div className="row align-items-center">
                        <div className="col-lg-12 table-responsive">
                        
                  <h2>Yet to be released!</h2>
                  <hr />
                          </div>
                        </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-law"
                  role="tabpanel"
                  aria-labelledby="nav-law-tab"
                >
                  <div className="row align-items-center">
                          <div className="col-lg-12 table-responsive">
                            
                  <h2>Yet to be released!</h2>
                  <hr />
                          </div>
                        </div>
                  <br />
                  <hr />
                  <br />
                  {/*<div class="row align-items-center">
          <div class="col-lg-6">
          <div class="faq-image pr-20">
              <h2>NOC Letter 2021-2022</h2>
              <hr>
              <object height="500" data="assets/permission/LAW/NOC.PDF#toolbar=0" type="application/pdf" width="100%">
          
                      <p>It appears you don't have a PDF plugin for this browser.
                          No biggie... you can <a href="assets/permission/LAW/NOC.PDF#toolbar=0">click here to
                          download the PDF file.</a>
                      </p>
          
                  </object>
              <!--<embed src="assets/pdf/eli.pdf#toolbar=0" width="100%" height="500px"> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default AdmissionForm