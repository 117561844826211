import React from "react";

function FoundersMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637520321_2426-org.jpg"
                alt=""
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <p style={{}}>
                <strong>
                  Shastri Swami Premswarup Das Ji
                  <br />
                </strong>
                Founder and Managing Trustee
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Founder's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                I extend my warm wishes and welcome you all to Shree
                Swaminarayan Nursing College, (SSNC) Kalol, Gandhinagar
                established in 2017 for the service of the rural population of
                Kalol. The college is not only a medium for imparting nursing
                education but also as a molding ground to turn out honest,
                dynamic, and competent individuals ready to take the noble
                responsibility as nursing professionals. The institute is
                turning out to be a reputed institution with all-round quality
                learning due to great leadership, dedicated faculty,
                ever-increasing facilities, deep concern for student’s
                development, and an insatiable urge to improve and expand. We at
                SSNC believe in discipline, strict student attendance, the
                teaching of moral character, the latest audiovisual mode of
                teaching, and extracurricular activities. Departments are fully
                equipped with basic as well as the latest equipment, models,
                charts, specimens. We are striving to make this institute a
                model institution of nursing. With each new session, new
                developments are introduced to maintain a high standard. For
                each individual, we provide numerous platforms where they can
                explore, exhibit, and express their ideas. They can interact and
                train within the medical sector, thus opening for them a world
                of opportunities where they can find employment at the nation's
                leading healthcare centers. Our commitment to our vision and
                dedication of our staff and students ensure that SSNC is well
                poised for success in the ever-demanding and changing world of
                healthcare. Let us join together to make a bright career in the
                noble profession of nursing, which dignifies the service to the
                sick and humanity.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default FoundersMessage;
