import React from 'react'

function simsAboutCollege() {

  const banner1 =
  "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1713849341121.jpeg";

  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <div className="row">

        <h2 className="text-dark text-center">
          ABOUT COLLEGE
            <br />
        </h2>

        <div className="col-12">        
            <div className="campus-image">
              <img src={banner1} alt="Image" style={{ height: '500px', width: '1800px' }}/>
            </div>
            <br />      
        </div>

        
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>College Name</th>
                <td>Swaminarayan Institute of Medical Sciences and Research</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>Ahmedabad Mehsana Highway, At & Post: Saij, Ta: Kalol, Dist.: Gandhinagar, State: Gujarat, Pin Code: 382725.
                    <p>Mobile: +91-9114139114</p>
                    <p>Email: deanoffice.sims@swaminarayanuniversity.ac.in</p>
                </td>
              </tr>
              <tr>
                <th>University Details</th>
                <td><p>MBBS</p>
                    <p>Number of Seats: 150</p>
                </td>
              </tr>
              <tr>
                <th>Website</th>
                <td><a href="https://www.swaminarayanuniversity.ac.in/sims">https://www.swaminarayanuniversity.ac.in/sims</a></td>
              </tr>
            </tbody>
          </table>
        

        <div className="col-md-12">

          <p style={{ textAlign: "justify" }}>
      
          Welcome to the Swaminarayan Institute of Medical Sciences and Research (SIMSR), a premier institution 
          dedicated to excellence in medical education, research, and healthcare delivery. Situated in the heart 
          of Kalol, our institute embodies the ethos of compassion, innovation, and integrity, guided by the principles 
          of humanity and service to society.
          Established with the vision of nurturing competent healthcare professionals and advancing medical science, 
          SIMSR offers a comprehensive range of academic programs, cutting-edge research initiatives, and 
          state-of-the-art clinical services to meet the healthcare needs of the community and beyond.

          </p>
          
        </div>
      </div>
      <br/>
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-dark">Academic Excellence:</h4>
          <p>
          At SIMSR, we are committed to providing high-quality medical education that integrates the latest 
          advancements in healthcare with ethical and compassionate patient care. Our faculty comprises 
          experienced clinicians, researchers, and educators who are dedicated to nurturing the next 
          generation of healthcare leaders.
          </p>
          <p>
          We offer undergraduate programs in various medical disciplines, including Medicine, Surgery, 
          Pediatrics, Obstetrics & Gynecology, Psychiatry, and more. Our curriculum is designed to instill 
          a strong foundation in basic sciences, clinical skills, and professionalism while fostering 
          critical thinking, communication, and teamwork.
          </p>
          <br/>
          <h4 className="text-dark">Clinical Services :</h4>
          <p>
          In addition to academic excellence and research, SIMSR is committed to providing exemplary 
          clinical care to our patients. We operate modern healthcare facilities equipped with advanced 
          medical technology and staffed by highly skilled healthcare professionals. Our hospitals and 
          clinics serve as training grounds for our students and residents, offering hands-on clinical 
          experience under the guidance of experienced mentors.
          </p>
          <br/>
          <h4 className="text-dark">Vision for the Future :</h4>
          <p>
          As we look to the future, SIMSR remains committed to excellence, innovation, and service. 
          We aspire to be a global leader in medical education, research, and healthcare delivery, 
          continuously striving for excellence in all aspects of our mission. With a focus on 
          continuous improvement, collaboration, and compassion, we aim to shape the future of 
          medicine and make a meaningful difference in the lives of individuals and communities 
          worldwide.
          </p>
          <p>
          Welcome to the Swaminarayan Institute of Medical Sciences and Research, where we are 
          dedicated to advancing knowledge, transforming lives, and healing humanity. Join us on 
          this journey of discovery, innovation, and service to create a healthier, more equitable 
          world for all.
          </p> 
        </div>
      </div>
      <br />
      <br />
    </div>
      </>
    </div>
  )
}

export default simsAboutCollege;
