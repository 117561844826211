import React from 'react'

function AcademicRegulations() {
  return (
    <div className='AcademicRegulations'>
        <div className="faq-area ptb-80">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-12">
        <div className="faq-image pr-20">
          <h2>Academic Regulation</h2>
          <hr />
          <object
            height={800}
            data="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+New+Regulations-29.07.2023.pdf#toolbar=0"
            type="application/pdf"
            width="100%"
          >
            <p>
              It appears you don't have a PDF plugin for this browser. No
              biggie... you can{" "}
              <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+New+Regulations-29.07.2023.pdf#toolbar=0">
                click here to download the PDF file.
              </a>
            </p>
          </object>
          {/*<embed src="assets/pdf/eli.pdf#toolbar=0" width="100%" height="500px">*/}
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default AcademicRegulations