import axios from "axios";
import React, { useState } from "react";
import { sessionOpt } from "../../Data/sessionData";

import { EXAM_RESULTS_DRAFT } from "../../Utils/apiConst";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import "./style.scss";

import { Link, useNavigate } from "react-router-dom";

function DraftResults() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const calculateGrade = (p) => {
    if (p >= 85) return "O+";
    if (p >= 70 && p <= 84.99) return "O";
    if (p >= 60 && p <= 69.99) return "A";
    if (p >= 55 && p <= 59.99) return "B+";
    if (p >= 48 && p <= 54.99) return "B";
    if (p >= 36 && p <= 47.99) return "C";
    if (p < 36) return "D";
    console.log(p);
  };

  const calculateGradePoint = (p) => {
    // if(p>=85) return "O+"
    // if (p>=70 && p<=84.99 ) return "O"
    // if (p>=60    && p<=69.99 ) return "A"
    // if (p>=55 && p<=59.99 ) return "B+"
    // if (p>=48 && p<=54.99 ) return "B"
    // if (p>=36 && p<=47.99 ) return "C"
    // if (p<36) return "D"

    // console.log(p)
    console.log(parseFloat(p * 10).toFixed(2));
    return parseFloat(p * 10).toFixed(2);
  };

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology,Kalol",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College, Kalol",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College, Kalol",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,Kalol",
    },
    {
      id: 1111010,
      name: "Faculty of Education",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
      program_name: "Bachelor of Education ",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College, Kalol",

      program_name: "Bachelor of Science ",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOMEOPATHY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
    },
    {
      id: 1111008,
      name: "FACULTY OF PHARMACY",
      college_type_id: "PHARMACY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Pharmacy College, Kalol",
    },
    {
      id: 1111009,
      name: "FACULTY OF IT AND COMPUTER SCIENCE",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name: "FACULTY OF IT AND COMPUTER SCIENCE, Kalol",
    },
  ];

  const [studentId, setStudentId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(0);

  const [flag, setFlag] = useState(0);

  const [examResults, setExamResults] = useState([]);
  const [totalsum, setTotalSum] = useState();
  const [totalsum_emin, setTotalSumEmin] = useState();
  const [totalsum_imin, setTotalSumImin] = useState();
  const [isPass, setIsPass] = useState(0);
  const [totalsum_imax, setTotalSumImax] = useState();
  const [totalsum_imarks, setTotalSumImarks] = useState();
  const [totalsum_emarks, setTotalSumEmarks] = useState();
  const [totalsum_obt, setTotalSumobt] = useState();
  const [totalabsent, setTotalAbsent] = useState();
  const [show, setShow] = useState(true);
  const [totalcredits, setTotalCredits] = useState();

  const getResults = async () => {
    if (!studentId || !sessionId)
      return toast.error("Please fill Student Id and Session");
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${EXAM_RESULTS_DRAFT}?student_id=${studentId}&session_id=${sessionId}`,
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length === 0) return toast.error("No Data Found");
        if (res.data.data.data[0].i_max == null) {
          setShow(false);
        }
        const result = Object.values(
          res.data.data.data.reduce((acc, curr) => {
            const subject = curr.sub_code;
            if (!acc[subject]) {
              acc[subject] = { ...curr };
              acc[subject].e_marks = Number(acc[subject].e_marks);
              acc[subject].e_max = Number(acc[subject].e_max);
              acc[subject].e_min = Number(acc[subject].e_min);
              acc[subject].i_marks = Number(acc[subject].i_marks);
              acc[subject].i_max = Number(acc[subject].i_max);
              acc[subject].i_min = Number(acc[subject].i_min);
              acc[subject].e_grace = Number(acc[subject].e_grace);
            } else {
              acc[subject].e_marks += Number(curr?.e_marks);
              acc[subject].e_max += Number(curr?.e_max);
              acc[subject].e_min += Number(curr?.e_min);
              acc[subject].i_marks += Number(curr?.i_marks);
              acc[subject].i_max += Number(curr?.i_max);
              acc[subject].i_min += Number(curr?.i_min);
              acc[subject].credit += Number(curr.credit);
              acc[subject].e_grace =
                Number(acc[subject].e_grace) + Number(curr.e_grace);
            }
            return acc;
          }, {})
        );

        console.log(result);

        setExamResults({ ...res.data.data, data: result });
        var sum = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum = sum;
          else sum += item.e_max;
        });
        console.log(sum);
        setTotalSum({ emax: sum });

        var sum_emin = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_emin = sum_emin;
          else sum_emin += item.e_min;
        });
        console.log(sum_emin);
        setTotalSumEmin({ emin: sum_emin });

        var sum_imin = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_imin = sum_imin;
          else sum_imin += item.i_min;
        });
        console.log(sum_imin);
        setTotalSumImin({ imin: sum_imin });

        // Calculate sum_imax
        var sum_imax = 0;
        result?.forEach((item) => {
          if (!item.is_not_countable) {
            sum_imax += Number(item.i_max); // Ensure i_max is treated as a number
          }
        });
        console.log(sum_imax);
        setTotalSumImax({ imax: sum_imax });

        // Calculate sum_imarks
        var sum_imarks = 0;
        result?.forEach((item) => {
          if (!item.is_not_countable) {
            sum_imarks += Number(item.i_marks); // Ensure i_marks is treated as a number
          }
        });

        console.log("internal Marks - ", sum_imarks);
        setTotalSumImarks({ i_marks: sum_imarks });

        var sum_emarks = 0;
        result?.forEach((item) => {
          if (!item.is_not_countable) {
            sum_emarks += Number(item.e_marks) + Number(item.e_grace);
          }
        });
        console.log(sum_emarks);
        setTotalSumEmarks({ emarks: sum_emarks });

        var sum_obt = 0;
        result?.forEach((item) => {
          if (!item.is_not_countable) {
            sum_obt +=
              Number(item.e_marks) +
              Number(item.e_grace) +
              Number(item.i_marks);
          }
        });

        setTotalSumobt({ obt: sum_obt });
        var sum_credits = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_credits = sum_credits;
          else sum_credits += item.credit;
        });
        console.log(sum_credits);
        setTotalCredits({ credits: sum_credits });

        var absent = 0;
        result?.forEach((item) => {
          if (item.i_absent == 1 || item.e_absent == 1) {
            absent++;
          }
        });

        var flag = 0;
        for (const item of result) {
          if (item.i_absent == 1 || item.e_absent == 1) {
            flag = 1;
            break;
          }
          if (
            item.e_min > item.e_grace + item.e_marks &&
            item.e_grace != null &&
            item.e_grace != 0
          ) {
            flag = 1;
            console.log("grace min " + item.e_min);
            console.log("grace " + item.subject);
            console.log("grace mark " + item.e_grace);
            console.log("grace flag " + flag);
            break;
          } else if (
            item.e_min > item.e_marks &&
            (item.e_grace == null || item.e_grace == 0)
          ) {
            flag = 1;
            console.log("min flag" + flag);
            break;
          }
        }
        if (!flag) {
          setIsPass(1);
        } else {
          setIsPass(0);
        }
        // if(!flag) setIsPass(1)

        console.log(absent);
        setTotalAbsent({ absent: absent });

        setFlag(1);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  console.log(examResults);

  const Rechecking = () => {
    navigate("/reCheckingForm");
  };
  const Reassessment = () => {
    navigate("/reAssessmentForm");
  };

  const getTotalObtainedMarks = (i) => {
    const eMarks = i?.e_marks || 0;
    const iMarks = i?.i_marks || 0;
    const eGrace = i?.e_grace || 0;
    return Number(eMarks) + Number(iMarks) + Number(eGrace);
  };

  const getPercentage = (i) => {
    const totalObtainedMarks = getTotalObtainedMarks(i);
    const totalMaxMarks = Number(i?.e_max) + Number(i?.i_max);
    return (totalObtainedMarks / totalMaxMarks) * 100;
  };

  const getGrade = (i) => {
    return calculateGrade(getPercentage(i));
  };

  const getGradePoint = (i) => {
    const totalObtainedMarks = getTotalObtainedMarks(i);
    const totalMaxMarks = Number(i?.e_max) + Number(i?.i_max);

    return calculateGradePoint(totalObtainedMarks / totalMaxMarks);
  };

  const getCreditGradePoint = (i) => {
    return getGradePoint(i) * Number(i?.credit);
  };

  console.log("examResults?.data -", examResults?.data);

  return (
    <div className="Enrollment-area mt-5 pb-70 ">
      <Loader />

      {!flag ? (
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src="/assets/images/favicon.png"
              className="mr-5"
              width="150"
              alt="logo"
            />
            <div className="ml-5 text-center">
              <h2 class="text-uppercase mx-5"> Swaminarayan University</h2>
            </div>
          </div>
          <div className="row">
            <div className="card shadow-sm border-0">
              <h3 className="card-header text-center">
                PROVISIONAL EXAMINATION RESULTS
                <br />
                કામચલાઉ પરીક્ષાના પરિણામો
              </h3>

              <div className="card-body">
                <div className="row d-flex justify-content-center p-4">
                  <div className="col-md-12 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Select Academic Year</label>
                      <select
                        name="year"
                        id="year"
                        className="form-control"
                        value={sessionId}
                        onChange={(e) => setSessionId(e.target.value)}
                      >
                        <option value="">Select Academic Year</option>
                        {sessionOpt.map((i, key) => (
                          <option value={i.id}>{i.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Enrollment Number</label>
                      <input
                        type="text"
                        name="reg_no"
                        id="reg_no"
                        className="form-control"
                        placeholder="Enter Enrollment Number"
                        value={studentId}
                        onChange={(e) => setStudentId(e.target.value)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="col-md-12 mt-4">
                    <button
                      className="btn btn-primary d-print-none float-right"
                      onClick={getResults}
                    >
                      Show Result
                    </button>
                  </div>

                  {/* <div className="col-md-6 mt-4">
                                            <div className="form-group">
                                                <label htmlFor="">Enter Captcha Code</label>
                                                <input type="text" name="captcha" id="captcha" className="form-control" placeholder='Enter Captcha Code' />

                                            </div>

                                        </div> */}

                  {/* <div className="col-md-12 mt-3 ">
                                            <button onClick={getResults} className="btn btn-success float-end ">Submit</button>
                                        </div> */}
                </div>
                <div className="row "></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-lg " ref={componentRef}>
          <div className="d-flex justify-content-center align-items-center clg-details">
            <img
              src="/assets/images/favicon.png"
              className="mr-5"
              width="150"
              alt="logo"
            />
            <div className="mx-3 text-center clg ">
              {/* <h4>સ્વામિનારાયણ યુનિવર્સિટી, કલોલ</h4> */}
              <h2 class="text-uppercase"> Swaminarayan University</h2>
            </div>
          </div>

          <br />

          <h5 class="text-center">
            PROVISIONAL STATEMENT OF MARKS AND GRADES OF{" "}
            {examResults?.data[0]?.program || "UG"}{" "}
            {examResults?.data[0]?.program == "DOCTORATE" ? (
              <span>COURSE WORK</span>
            ) : null}{" "}
            EXAMINATION{" "}
            {examResults?.data[0]?.program == "DOCTORATE" ? (
              <span>
                Ph.D. 2022 BATCH-2 <br />
              </span>
            ) : null}{" "}
            &nbsp;
            {months[Number(examResults?.data[0]?.month) - 1] ||
              "JAN" ||
              "MARCH"}
            -{examResults?.data[0]?.year || "2023"}
          </h5>

          <br />

          {examResults?.examStatus == "DIFF" ? (
            <div>
              {examResults?.data[0]?.college_id == 1111008 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check Pharmacy Results{" "}
                    <Link to={"/Results-pharmacy"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : examResults?.data[0]?.college_id == 1111000 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check Engineering Results{" "}
                    <Link to={"/Results-eng"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : examResults?.data[0]?.college_id == 1111009 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check BCA Results{" "}
                    <Link to={"/Results-eng"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : null}
            </div>
          ) : (
            <div className=" ">
              <table className="table table-bordered result ">
                <thead>
                  <tr>
                    <th>Enrollment Number</th>
                    <td>{examResults?.data[0]?.student_id}</td>
                    <th>Student Name </th>
                    <td>{examResults?.data[0]?.name}</td>
                  </tr>
                  <tr>
                    <th>Faculty Name</th>
                    <td>{examResults?.data[0]?.college}</td>

                    <th>Program</th>
                    <td>{examResults?.data[0]?.department}</td>
                  </tr>

                  {examResults?.data[0]?.program != "DOCTORATE" ? (
                    <tr>
                      <th>College Name</th>
                      <td>
                        {
                          facultyData.find(
                            (item) =>
                              item.id == examResults?.data[0]?.college_id
                          )?.colloge_name
                        }
                      </td>

                      <th>
                        {examResults?.data[0]?.department == "B.H.M.S." ||
                        examResults?.data[0]?.department == "BAMS"
                          ? "Year"
                          : examResults?.data[0]?.department == "B.Sc. Nursing"
                          ? "Semester"
                          : examResults?.data[0]?.department ==
                            "Post Basic B.Sc. Nursing"
                          ? "Year"
                          : "Semester"}
                      </th>

                      <td>
                        {" "}
                        {examResults?.data[0]?.department == "B.H.M.S."
                          ? examResults?.data[0]?.class_name
                          : examResults?.data[0]?.department == "B.Sc. Nursing"
                          ? examResults?.data[0]?.semester
                          : // : examResults?.data[0]?.department ==
                          //   "Post Basic B.Sc. Nursing"
                          // ? "2st Year "
                          examResults?.data[0]?.department ==
                            "Post Basic B.Sc. Nursing"
                          ? examResults?.data[0]?.semester == "Sem 1" ||
                            examResults?.data[0]?.semester == "Sem 2"
                            ? "1st Year"
                            : "2nd Year"
                          : examResults?.data[0]?.department == "BAMS"
                          ? "1st Prof BAMS"
                          : examResults?.data[0]?.semester}{" "}
                      </td>
                    </tr>
                  ) : null}
                </thead>

                {}
              </table>

              {/* <table className="table result table-bordered ">
                <thead>
                  <tr>
                    <th rowSpan="2" className="text-center">
                      {examResults?.data[0]?.program == "DOCTORATE"
                        ? "Course Work Examination"
                        : "Course Name"}
                    </th>

                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        Credit
                      </th>
                    ) : (
                      ""
                    )}

                    <th className="text-center" colSpan="4">
                      {" "}
                      {examResults?.data[0]?.department == "B.H.M.S." ||
                      examResults?.data[0]?.college ==
                        "DOCTORAL AND POST DOCTORAL PROGRAMME" ? (
                        "University Exam"
                      ) : (
                        <span>
                          {" "}
                          University Exam <br />{" "}
                          {totalsum_imarks?.i_marks == 0 ||
                          totalsum_imarks?.i_marks == null
                            ? " "
                            : "Internal Evaluation"}
                        </span>
                      )}
                    </th>

                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        Grade
                      </th>
                    ) : (
                      ""
                    )}
                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        GP
                      </th>
                    ) : (
                      ""
                    )}
                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        CGP
                      </th>
                    ) : (
                      ""
                    )}

                    {examResults?.data[0]?.department == "BAMS" ||
                    examResults?.data[0]?.department == "B.H.M.S." ? null : (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        Result
                      </th>
                    )}
                  </tr>
                  <tr>
                    <th className="text-center">Max</th>
                    <th className="text-center">Min</th>
                    <th className="text-center">Obtained</th>
                    <th className="text-center">
                      {" "}
                      {examResults?.data[0]?.department == "BAMS" ||
                      examResults?.data[0]?.department == "B.H.M.S."
                        ? "Result"
                        : "Total"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {examResults &&
                    examResults?.data?.map((i, key) => (
                      <tr key={key}>
                        <td>
                          {i?.sub_code} - {i?.subject}
                        </td>
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">{i?.credit} </td>
                        ) : (
                          ""
                        )}

                        <td className="text-center">
                          {i?.e_max == 0 ? "-" : i?.e_max} <br />{" "}
                          {totalsum_imarks?.i_marks == 0 ||
                          totalsum_imarks?.i_marks == null
                            ? null
                            : i?.i_max == 0
                            ? "-"
                            : i?.i_max}{" "}
                          <br />{" "}
                        </td>

                        <td className="text-center">
                          {i?.e_min == 0 ? "-" : i?.e_min} <br />{" "}
                          {totalsum_imarks?.i_marks == 0 ||
                          totalsum_imarks?.i_marks == null
                            ? null
                            : i?.i_min == 0
                            ? "-"
                            : i?.i_min}{" "}
                        </td>

                        <td className="text-center">
                          {i?.e_absent == 1 ? (
                            <span className="text-danger">
                              <b>A</b>
                            </span>
                          ) : i?.e_marks == 0 ? (
                            "-"
                          ) : i?.e_marks == null ? (
                            "-"
                          ) : i?.e_marks == "" ? (
                            "-"
                          ) : i?.e_grace == null || i?.e_grace == 0 ? (
                            i?.e_marks
                          ) : (
                            i?.e_marks + "+" + i?.e_grace
                          )}{" "}
                          <br />
                          {i?.i_absent == 1 ? (
                            <span className="text-danger">
                              <b>A</b>
                            </span>
                          ) : i?.i_max == null ? (
                            ""
                          ) : totalsum_imarks?.i_marks == 0 ||
                            totalsum_imarks?.i_marks == null ? null : (
                            i?.i_marks
                          )}
                        </td>
                        <td></td>
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null || i?.e_grace == 0
                              ? Number(i?.i_marks) + Number(i?.e_marks)
                              : Number(i?.i_marks) +
                                Number(i?.e_marks) +
                                Number(i?.e_grace)}
                          </td>
                        ) : (
                          ""
                        )}
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? calculateGrade(
                                  ((Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))) *
                                    100
                                )
                              : calculateGrade(
                                  ((Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))) *
                                    100
                                )}
                          </td>
                        ) : (
                          ""
                        )}

                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? calculateGradePoint(
                                  (Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))
                                )
                              : calculateGradePoint(
                                  (Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))
                                )}{" "}
                          </td>
                        ) : (
                          ""
                        )}

                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? calculateGradePoint(
                                  ((Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))) *
                                    Number(i?.credit).toFixed(2)
                                )
                              : calculateGradePoint(
                                  ((Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))) *
                                    Number(i?.credit).toFixed(2)
                                )}{" "}
                          </td>
                        ) : (
                          ""
                        )}

                        <td className="text-center">
                        
                          {(Number(i?.i_marks) >= i?.i_min &&
                            Number(i?.e_marks) + Number(i?.e_grace) >=
                              i?.e_min) ||
                          (i?.e_marks == null && Number(i?.i_absent != 1)) ? (
                            <p className="text-success">
                              <b> P </b>{" "}
                            </p>
                          ) : (
                            

                            <p className="text-danger">
                              <b>F </b>
                            </p>
                          )}{" "}
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <th className="text-center">Total</th>
                    {totalcredits?.credits != 0 ? (
                      <td className="text-center"> {totalcredits?.credits} </td>
                    ) : (
                      ""
                    )}

                    <td className="text-center">
                      {totalsum?.emax} <br />
                      {totalsum_imax?.imax == 0 ? "" : totalsum_imax?.imax}
                    </td>
                    {totalcredits?.credits == 0 ? (
                      <td className="text-center">
                        {examResults?.data[0]?.department == "B.H.M.S."
                          ? totalsum_emin?.emin
                          : " "}
                      </td>
                    ) : (
                      <td className="text-center">
                        - <br /> -
                      </td>
                    )}
                    <td className="text-center">
                      {totalsum_emarks?.emarks <= 0 ? (
                        <span className="text-danger">
                          <b>F</b>
                        </span>
                      ) : (
                        totalsum_emarks?.emarks
                      )}{" "}
                      <br />
                      {totalsum_imarks?.i_marks == 0
                        ? ""
                        : totalsum_imarks?.i_marks}
                    </td>

                    <th className="text-center">
                      {examResults?.data[0]?.department == "BAMS" ||
                      examResults?.data[0]?.department == "B.H.M.S."
                        ? ""
                        : totalsum_obt?.obt}
                    </th>
                    {totalcredits?.credits != 0 ? <th colSpan={2}></th> : ""}

                    {totalcredits?.credits != 0 && show ? (
                      <th className="text-center">
                        {totalabsent?.absent >= 1
                          ? "-"
                          : examResults?.data
                              ?.reduce(
                                (total, i) =>
                                  i?.is_not_countable
                                    ? total + 0
                                    : total +
                                      calculateGradePoint(
                                        (Number(i?.i_marks) +
                                          Number(i?.e_marks) +
                                          Number(i?.e_grace)) /
                                          (Number(i?.e_max) + Number(i?.i_max))
                                      ) *
                                        Number(i?.credit),
                                0
                              )
                              .toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                    {totalcredits?.credits != 0 ? <th></th> : ""}
                  </tr>
                  <tr className="text-center">
                    <th className="text-center"> Result </th>
                    <td colSpan={5} className="text-center">
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          total +
                          (calculateGradePoint(
                            (Number(i?.i_marks) +
                              Number(i?.e_marks) +
                              Number(i?.e_grace)) /
                              (Number(i?.e_max) + Number(i?.i_max))
                          ) <
                            3.6),
                        0
                      ) ||
                      !isPass ? (
                        <p className="text-danger">
                          <b> FAIL </b>{" "}
                        </p>
                      ) : (
                        <p className="text-success">
                          <b>PASS </b>
                        </p>
                      )}
                    </td>

                    {totalcredits?.credits != 0 ? <th>SGPA:</th> : ""}

                    {totalcredits?.credits != 0 ? (
                      <th colSpan={3} className="text-center">
                        {totalabsent?.absent >= 1 ||
                        examResults?.data?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                (calculateGradePoint(
                                  (Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))
                                ) <
                                  3.6),
                          0
                        )
                          ? "-"
                          : totalabsent?.absent >= 1
                          ? "-"
                          : (
                              examResults?.data?.reduce(
                                (total, i) =>
                                  i?.is_not_countable
                                    ? total + 0
                                    : total +
                                      calculateGradePoint(
                                        (Number(i?.i_marks) +
                                          Number(i?.e_marks) +
                                          Number(i?.e_grace)) /
                                          (Number(i?.e_max) + Number(i?.i_max))
                                      ) *
                                        Number(i?.credit),
                                0
                              ) / totalcredits?.credits
                            ).toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </tbody>
              </table> */}

              <table className="table result table-bordered">
                <thead>
                  <tr>
                    <th rowSpan="2" className="text-center">
                      {examResults?.data[0]?.program === "DOCTORATE"
                        ? "Course Work Examination"
                        : "Course Name"}
                    </th>

                    {totalcredits?.credits !== 0 && (
                      <th rowSpan="2" className="text-center">
                        Credit
                      </th>
                    )}

                    <th className="text-center" colSpan="4">
                      {examResults?.data[0]?.department === "B.H.M.S." ||
                      examResults?.data[0]?.college ===
                        "DOCTORAL AND POST DOCTORAL PROGRAMME" ? (
                        "University Exam"
                      ) : (
                        <span>
                          University Exam <br />
                          {totalsum_imarks?.i_marks === 0 ||
                          totalsum_imarks?.i_marks == null
                            ? " "
                            : "Internal Evaluation"}
                        </span>
                      )}
                    </th>

                    {totalcredits?.credits !== 0 && (
                      <>
                        <th rowSpan="2" className="text-center">
                          Grade
                        </th>
                        <th rowSpan="2" className="text-center">
                          GP
                        </th>
                        <th rowSpan="2" className="text-center">
                          CGP
                        </th>
                      </>
                    )}

                    {examResults?.data[0]?.department !== "BAMS" &&
                      examResults?.data[0]?.department !== "B.H.M.S." && (
                        <th rowSpan="2" className="text-center">
                          Result
                        </th>
                      )}
                  </tr>
                  <tr>
                    <th className="text-center">Max</th>
                    <th className="text-center">Min</th>
                    <th className="text-center">Obtained</th>
                    <th className="text-center">
                      {examResults?.data[0]?.department === "BAMS" ||
                      examResults?.data[0]?.department === "B.H.M.S."
                        ? "Result"
                        : "Total"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {examResults?.data?.map((i, key) => (
                    <tr key={key}>
                      <td>
                        {i?.sub_code} - {i?.subject}
                      </td>
                      {totalcredits?.credits !== 0 && (
                        <td className="text-center">{i?.credit}</td>
                      )}
                      <td className="text-center">
                        {i?.e_max === 0 ? "-" : i?.e_max} <br />
                        {totalsum_imarks?.i_marks === 0 ||
                        totalsum_imarks?.i_marks == null
                          ? null
                          : i?.i_max === 0
                          ? "-"
                          : i?.i_max}
                      </td>
                      <td className="text-center">
                        {i?.e_min === 0 ? "-" : i?.e_min} <br />
                        {totalsum_imarks?.i_marks === 0 ||
                        totalsum_imarks?.i_marks == null
                          ? null
                          : i?.i_min === 0
                          ? "-"
                          : i?.i_min}
                      </td>
                      <td className="text-center">
                        {/* External Marks */}
                        {i?.e_absent === 1 ? (
                          <span className="text-danger">
                            <b>A</b>
                          </span>
                        ) : +i?.e_marks === 0 ||
                          i?.e_marks == null ||
                          i?.e_marks === "" ? (
                          "-"
                        ) : i?.e_grace === 0 || i?.e_grace == null ? (
                          i?.e_marks
                        ) : (
                          i?.e_marks + Number(i?.e_grace)
                        )}
                        <br />
                        {/* Internal Marks */}
                        {i?.i_absent === 1 ? (
                          <span className="text-danger">
                            <b>A</b>
                          </span>
                        ) : i?.i_max == null ? (
                          ""
                        ) : +totalsum_imarks?.i_marks === 0 ||
                          totalsum_imarks?.i_marks == null ? null : (
                          +i?.i_marks
                        )}
                      </td>

                      {/* Total Credits */}
                      {totalcredits?.credits !== 0 && (
                        <td>
                          {i?.i_absent === 1
                            ? "A"
                            : +i?.i_marks +
                              +i?.e_marks +
                              (+i?.e_grace || 0)}{" "}
                          {/* Sum numbers */}
                        </td>
                      )}

                      {totalcredits?.credits !== 0 && (
                        <td className="text-center">{getGrade(i)}</td>
                      )}
                      {totalcredits?.credits !== 0 && (
                        <td className="text-center">{getGradePoint(i)}</td>
                      )}
                      {totalcredits?.credits !== 0 && (
                        <td className="text-center">
                          {getCreditGradePoint(i).toFixed(2)}
                        </td>
                      )}
                      <td className="text-center">
                        {(Number(i?.i_marks) >= i?.i_min &&
                          Number(i?.e_marks) + Number(i?.e_grace) >=
                            i?.e_min) ||
                        (i?.e_marks == null && Number(i?.i_absent) !== 1) ? (
                          <p className="text-success">
                            <b>P</b>
                          </p>
                        ) :  (examResults?.data[0]?.department === "B.H.M.S." && Number(i?.e_marks) ==0) ?
                        <p className="text-danger">
                        <b>NE</b>
                      </p>: (
                          <p className="text-danger">
                            <b>F</b>
                          </p>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th className="text-center">Total</th>
                    {totalcredits?.credits !== 0 && (
                      <td className="text-center">{totalcredits?.credits}</td>
                    )}
                    <td className="text-center">
                      {totalsum?.emax}
                      <br />
                      {totalsum_imax?.imax === 0 ? "" : totalsum_imax?.imax}
                    </td>
                    {totalcredits?.credits === 0 ? (
                      <td className="text-center">
                        {examResults?.data[0]?.department === "B.H.M.S."
                          ? totalsum_emin?.emin
                          : " "}
                      </td>
                    ) : (
                      <td className="text-center">
                        -
                        <br />-
                      </td>
                    )}
                    <td className="text-center">
                      {totalsum_emarks?.emarks <= 0 ? (
                        <span className="text-danger">
                          <b>F</b>
                        </span>
                      ) : (
                        totalsum_emarks?.emarks
                      )}
                      <br />
                      {totalsum_imarks?.i_marks === 0
                        ? ""
                        : totalsum_imarks?.i_marks}
                    </td>
                    <th className="text-center">
                      {examResults?.data[0]?.department === "BAMS" ||
                      examResults?.data[0]?.department === "B.H.M.S."
                        ? ""
                        : totalsum_obt?.obt}
                    </th>
                    {totalcredits?.credits !== 0 && <th colSpan={2}></th>}
                    {totalcredits?.credits !== 0 && show && (
                      <th className="text-center">
                        {totalabsent?.absent >= 1
                          ? "-"
                          : examResults?.data
                              ?.filter((s) => !s.is_not_countable)
                              ?.reduce(
                                (total, i) =>
                                  i?.is_not_countable
                                    ? total + 0
                                    : total +
                                      Number(
                                        calculateGradePoint(
                                          ((Number(i?.i_marks) +
                                            Number(i?.e_marks) +
                                            Number(i?.e_grace)) /
                                            (Number(i?.e_max) +
                                              Number(i?.i_max))) *
                                            Number(i?.credit)
                                        )
                                      ),
                                0
                              )
                              .toFixed(2)}
                      </th>
                    )}
                    {totalcredits?.credits !== 0 && <th></th>}
                  </tr>
                  <tr className="text-center">
                    <th className="text-center">Result</th>
                    <td colSpan={5} className="text-center">
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          total +
                          (calculateGradePoint(
                            (Number(i?.i_marks) +
                              Number(i?.e_marks) +
                              Number(i?.e_grace)) /
                              (Number(i?.e_max) + Number(i?.i_max))
                          ) <
                            3.6),
                        0
                      ) ||
                      !isPass ? (
                        <p className="text-danger">
                          <b>FAIL</b>
                        </p>
                      ) : (
                        <p className="text-success">
                          <b>PASS</b>
                        </p>
                      )}
                    </td>
                    {totalcredits?.credits !== 0 && <th>SGPA:</th>}
                    {totalcredits?.credits !== 0 && (
                      <th colSpan={3} className="text-center">
                        {totalabsent?.absent >= 1 ||
                        examResults?.data?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                (calculateGradePoint(
                                  (Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))
                                ) <
                                  3.6),
                          0
                        )
                          ? "-"
                          : totalabsent?.absent >= 1
                          ? "-"
                          : (
                              examResults?.data?.reduce(
                                (total, i) =>
                                  i?.is_not_countable
                                    ? total + 0
                                    : total +
                                      calculateGradePoint(
                                        (Number(i?.i_marks) +
                                          Number(i?.e_marks) +
                                          Number(i?.e_grace)) /
                                          (Number(i?.e_max) + Number(i?.i_max))
                                      ) *
                                        Number(i?.credit),
                                0
                              ) / totalcredits?.credits
                            ).toFixed(2)}
                      </th>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {examResults?.examStatus == "HOLD" ? null : (
            <>
              <div className="text-center mt-4">
                <h6>Nomenclature / Abbreviations</h6>
              </div>
              <div className="d-flex justify-content-around px-5 mt-4">
                <h6> P {"->"} Pass</h6>
                <h6> F {"->"} Fail</h6>
                <h6> A {"->"} Absent</h6>
                <h6> NE {"->"} Not Eligible</h6>
              </div>
            </>
          )}

          <h5 className="text-danger mt-4">Note *</h5>
          <p>
            <b>1. This is Computer Generate Statement of Marks</b>
            <br />
            {(examResults.data[0].college_id == "1111005" &&
              (examResults.data[0].semester == "1st Sem" ||
                examResults.data[0].semester == "1st Semester" ||
                examResults.data[0].semester == "Sem 1" ||
                examResults.data[0].semester == "SEM 1" ||
                examResults.data[0].semester == "Semester 1" ||
                examResults.data[0].semester == "Semester 1 ")) ||
            (examResults.data[0].college_id == "1111004" &&
              (examResults.data[0].semester == "1st Sem" ||
                examResults.data[0].semester == "1st Semester" ||
                examResults.data[0].semester == "Sem 1" ||
                examResults.data[0].semester == "SEM 1" ||
                examResults.data[0].semester == "Semester 1" ||
                examResults.data[0].semester == "Semester 1 ")) ||
            (examResults.data[0].college_id == "1111009" &&
              (examResults.data[0].semester == "1st Sem" ||
                examResults.data[0].semester == "1st Semester" ||
                examResults.data[0].semester == "Sem 1" ||
                examResults.data[0].semester == "SEM 1" ||
                examResults.data[0].semester == "Semester 1" ||
                examResults.data[0].semester == "Semester 1 ")) ||
            (examResults.data[0].college_id == "1111011" &&
              (examResults.data[0].semester == "1st Sem" ||
                examResults.data[0].semester == "1st Semester" ||
                examResults.data[0].semester == "Sem 1" ||
                examResults.data[0].semester == "SEM 1" ||
                examResults.data[0].semester == "Semester 1" ||
                examResults.data[0].semester == "Semester 1 ")) ? (
              <b>
                2. NEP GUIDELINES AND APPROVED PROFORMA SHALL BE FOLLOWED WHILE
                ISSUING ORIGINAL MARK SHEET.
              </b>
            ) : (
              <></>
            )}
          </p>

          <div className="row mt-2">
            <div className="col-sm-6">
              <button
                onClick={() => setFlag(0)}
                className="btn btn-primary d-print-none mx-3"
              >
                Back
              </button>

              {examResults?.examStatus == "HOLD" ? null : (
                <button
                  className="btn btn-primary  float-right"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
            </div>
            {examResults?.examStatus == "HOLD" ? null : (
              <>
                <div className="col-sm-3 float-right">
                  <button
                    className="btn btn-danger "
                    onClick={Rechecking}
                    type="button"
                  >
                    Rechecking
                  </button>
                </div>
                <div className="col-sm-3">
                  <button
                    className="btn btn-danger "
                    onClick={Reassessment}
                    type="button"
                  >
                    Re-Assessment
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DraftResults;
