import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  COMPANIES_GET,
  PLACEMENTS_GET,
  PLACEMENTS_GET2,
  STUDENT_INFO,
} from "../../Utils/InfoUploadingConst";

function PlacementsPharmacy() {
  const [data, setData] = useState([]);
  const [companydata, setCompanyData] = useState([]);
  const [studentdata, setStudentData] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: PLACEMENTS_GET2,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      // res.data.data.sort((a, b) => b.id - a.id);
      // setData(res.data.data);
      setData(res.data.data.filter((item) => item.college_id === 1111008));
      // console.log("placements data -", res.data.data);
    } catch (err) {
      console.log(err);
    }

    const config2 = {
      method: "get",
      url: COMPANIES_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config2);
      // res.data.data.sort((a, b) => b.id - a.id);
      // setCompanyData(res.data.data);
      setCompanyData(
        res.data.data.filter(
          (item) => item.status === "ACTIVE" && item.college_id === 1111008
        )
      );
      console.log("companydata -", companydata);
    } catch (err) {
      console.log(err);
    }

    const config3 = {
      method: "get",
      url: STUDENT_INFO,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config3);
      // res.data.data.sort((a, b) => b.id - a.id);
      setStudentData(
        res.data.data.filter(
          (item) => item.status === "ACTIVE" && item.college_id === 1111008
        )
      );
      console.log("studentdata -", studentdata);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("data -", data);
  return (
    <div className="PlacementDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <br />
            <h1 className="text-center">Placement Details</h1>
            <br />
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-header d-flex justify-content-between "></div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row caampuss">
                    {data?.map((item, key) => {
                      return (
                        <div className="col-12 col-sm-6 col-lg-2" key={key}>
                          <div className="campusplace cc" data-wow-delay="0.3s">
                            <div className="ii">
                              <img
                                src={`${
                                  item?.student_picture
                                    ? // ? item?.student_picture
                                      item?.student_picture.replace(/"/g, "")
                                    : // : "https://i.pravatar.cc/150?img=12"
                                      // "/assets/engineering/images/logo2.jpeg"
                                      "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1721453454293.jpeg"
                                }`}
                                alt="studentImage"
                                width="100%"
                                height="300px"
                              />
                            </div>
                            <div className="mt-3 campusplace">
                              <h6>
                                Name :{""}
                                {item?.student_name}
                              </h6>

                              <p className="">
                                <strong>
                                  Company :{""}
                                  {item?.companyname}
                                </strong>
                              </p>

                              <p className="">
                                <strong>
                                  Package:{""} {item?.package}
                                </strong>
                              </p>
                              <p className="">
                                <strong>
                                  Batch: {""}
                                  {item?.year}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlacementsPharmacy;
