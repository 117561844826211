import React from 'react'
import './OfficerProfile.scss'
import { useLocation } from 'react-router-dom'

function Provost() {

    const location = useLocation()
    // const data = location?.state?.data
    const data = {
        quote : "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things."
    }
    
    console.log("data -", data);

  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-4">
            <br/>
            <br/>
            <img
            //   src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/vc.jpg"
              src="../assets/images/academics/vc.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={476}
              height={400}
            />
            <br />
            <br />

       
          <p style={{  }}>
            <strong>
            Prof (Dr.) M. S. Rao
              <br />
            </strong>
            Provost,Swaminarayan University
          </p>
        </div>
        <div className="col-md-8">
          <h2>
            Provost (Vice-Chancellor)
            <br />
          </h2>
          <h4 className="text-danger text-bold">Prof (Dr.) M. S. Rao</h4>
          <h6 className="text-dark">Provost,Swaminarayan University</h6>
          <p style={{ textAlign: "justify" }}>
            Prof (Dr.) M. S. Rao is the Founder Provost (Vice-Chancellor) of Swaminarayan University. 
            He has over 20 years of progressive experience in the fields of AYUSH Medical Education, 
            Health Administration and Clinical Research. His professional history includes positions 
            as Director (Health Sciences) for the Faculty of Ayurveda, Homeopathy, Nursing and Pharmacy 
            at Shree Swaminarayan Vishwamangal Gurukul Group of Institutions, Gandhinagar, Gujarat and 
            Principal & Medical Superintendent. He also served at the Ministry of AYUSH, Government of 
            India as a W.H.O Sponsored Technical Consultant for International Cooperation.
          </p>
          <p style={{ textAlign: "justify" }}>
          Prof (Dr.) Rao hails from a traditional Ayurveda family of South India. He is an internationally renowned physician, researcher and educator. Over the years he has organized seminars and workshops for practitioners and students in New Zealand, South Africa, Singapore, South Korea, Rwanda, Malawi, Mozambique and Namibia.
          </p>
          <p style={{ textAlign: "justify" }}>
          He is a Senior NABH Assessor (National Accreditation Board for Hospitals) AYUSH, Quality Council of India, New Delhi and a National External Assessor for NQAS Standards of NHSRC (National Health System Resource Centre, Ministry of Health) for more than a decade. He is also a Member of Ayurveda Training & Accreditation Board (ATAB) set up by the Ministry of AYUSH for all Ayurveda educational programs conducted at foreign countries. As an expert administrator of Open Source Software (Moodle) he promotes e-learning environment in AYUSH domain. As a Technical Consultant (WHO), his expertise included registration of products for export by USFDA / EMEA / UK-MHRA, international market development, establishment of information cell in Indian embassies in foreign countries, international fellowship program for foreign nationals for AYUSH courses. In India, export policy and international regulatory cooperation, intellectual property rights and related WTO issues and health tourism. He has helped successfully to complete the establishment of India's leading 'All India Institute of Ayurveda' a postgraduate education institution with state-of-the-art tertiary care hospital at New Delhi under the Ministry of AYUSH, Government of India. He has the credit of establishing AYUSH Resource Centers in Indian Embassies of many countries.
          </p>
          <p style={{ textAlign: "justify" }}>
          Prof (Dr.)M. S. Rao presents the modern and scientific face of AYUSH. His practice, backed by clinical research based data, statistics and scientific documentation, makes it unique in AYUSH world. He not only introduced the 'new face' of modern AYUSH, but also responsible for the 'new phase' in AYUSH.
          </p>
          <p style={{ textAlign: "justify" }}>
          Starting in 1992, he has organized thousands of Ayurvedic & Yoga camps for the people in addition to being actively involved in research activities related to medicinal plant conservation, database building and training. He has travelled extensively in India and had the privilege of conducting consultations, panchakarma programs, lectures and workshops in countries like South Africa, Botswana, Mozambique, Namibia, Rwanda, Malaysia, Singapore, New Zealand, South Korea and Malawi.
          </p>
          <p style={{ textAlign: "justify" }}>
          He has contributed many scientific papers on Ayurveda and Yoga published in various national and international journals.
          </p>
        </div>
      </div>
      <br />
      <br />
      {
      data?.quote&&
    <div className="row justify-content-center align-content-center mt-5">
      <div className="col-md-12">
        <blockquote className="otro-blockquotes">
            <h4> Message -</h4>
            <h6 className="ml-5">{data?.quote}</h6>
          <span className="text-dark">- "Prof (Dr.) M. S. Rao"</span>
        </blockquote>
      </div>
    </div>
    }
      </div>
      </>
    </div>
  )
}

export default Provost;
