export const profileData = [
    {
        name:"Shastri Swami Premswarupdasji",
        role:"President",
        details:[
            "Shastri Swami Premswaroopdasji,a follower of Bhakti and Seva is the prime founder of Shree Swaminarayan Vishvamangal Gurukul, Kalol. Swamiji Started his seva as a saint in the Swaminarayan Mandir located at Bhuj. Swamiji spent a period of 20 years in Satsang at the place.",
            "One Gujarati proverb says “Sadhu to Chalta Bhala”. Swamiji left swaminarayan Mandir, Bhuj and arrived at Swaminarayan Mandir, Kalupur in 1985 where he arranged Satsang Khathas in the surrounding towns and villages. By humble order of Acharya Maharajshri, Swamiji accepted the services as the Mahant(Head) of Swaminarayan Mandir, situated at Siddhpur and stayed over there for the place Kalol, an entrance of North Gujarat Region and acquired a piece of barren land and dreamed to establish a gurukul providing education, shelter to helpless people and a Goshala.",
            "The first foundation stone was laid down by Param Pujya Dharm Dhurandhar 1008 Acharyashri Tejendraprasadji in the year 1993. Afterwards Swamiji continued Satsang, Kathas and religious meetings in the nearby Towns and Villages.A school was started in the year 1995, with primary first and then secondary, Higher Secondary, general section, Science section and school was flourished.",
            "Swamiji then started B.Ed, PTC, Degree, Pharmacy, Diploma Engineering and Degree Engineering College and M.E. at Kalol Gurukul campus. In 2012, a completely modernized Shree Swaminarayan International School was Shunya(Zero), a new world of temples of education was created by this visionary and missionary Sadhu. The temples which has no boundary of religion and caste, where only Goddess Maa Saraswati is worshiped."
        ],
        quote:"Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things.",
        photo:"assets/images/academics/academic-2.jpg"
    },
    {
        name: "Swami Harikrishnaswarupdasji",
        role: "Trustee Representative, Shree Swaminarayan Vishvamangal Gurukul",
        details:[
            "Swaminarayan University is a springboard for learning. It is committed to high quality teaching with a thriving interactive culture",
            "Swaminarayan University is a positive place where students can go to have fun with friends, talk about relevant issues and be heard without judgment. It's a place where students are challenged to make good decisions and lead in positive ways in their colleges.",
            "The principle that Swaminarayan University follows: Our work should improve people’s lives beyond the boundaries of campus. This century-old guiding philosophy applies to our academics, research, and outreach."
        ],
        quote: "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things.",
        photo: "assets/images/academics/vicepresident3.jpg"
    },
    {
        name:"Shastri Swami Bhaktavatsaldasji",
        role:"Vice President",
        details:[
            "A Civil Engineer from Gujarat Technical University Ahmedabad directs and controls the work and resources of the institutes and hospitals managed by Shree Swaminarayan Vishvamangal Gurukul, Kalol. His role and responsibility presently in the Trust and University include the recruitment and retention of the required numbers of staff to achieve the mission and objectives of the University. He reviews the performances of the constituent colleges/schools of the University and takes care of the financial matters including financial planning and sustainability, resource allocation, identification of new sources of income, the monitoring of expenditure to ensure that it is within appropriate levels, ensuring the linking of resource allocation to strategic and operational planning.",
            "He also ensures compliance with institute’s approved financial policies; ensuring all staff are aware of the existence and extent of the University’s financial regulations. He is a member of the Governing Body of more than 10 Institutes run by Shree Swaminarayan Vidhyamandal Gurukul Trust."
        ],
        quote:"Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect                    experiences they've had and synthesize new things.",
        photo:"assets/images/academics/vicepresident-1.jpg"
    },
    {
        name:"Swami Bhaktinandandasji",
        role:"Vice President",
        details:[
            "He is young and energetic and has experience of being associated with the Trust since the last many years, in administration of the various educational institutes attached with the Trust. He has done his Bachelors (B.A.) in Sanskrit and at present pursuing M.A.  He is a member of the Institutes run by Shree Swaminarayan Vishvamangal Gurukul, Kalol. His present responsibilities in the Trust and University are Administration and recruitment of faculty, implement the policies formulated by Shree Swaminarayan Vishvamangal Gurukul, Kalol, ensure the recruitment and retention of well-motivated, trained and developed staff to ensure that it achieves the mission and objectives.  He also ensures institutional effectiveness through professional management of the College.",
            "He annually evaluates the performances of employees and approves the annual budget. Managing and monitoring of implementation of the resource allocation process within the institute.  He is also a member of the Governing Body ."
        ],
        quote:"Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect                    experiences they've had and synthesize new things.",
        photo:"assets/images/academics/vicepresident-2.jpg"
    },
    {
        name:"Prof (Dr.) M. S. Rao",
        role:"Provost,Swaminarayan University",
        details:[
            "Prof (Dr.) M. S. Rao is the Founder Provost (Vice-Chancellor) of Swaminarayan University. He has over 20 years of progressive experience in the fields of AYUSH Medical Education, Health Administration and Clinical Research. His professional history includes positions as Director (Health Sciences) for the Faculty of Ayurveda, Homeopathy, Nursing and Pharmacy at Shree Swaminarayan Vishwamangal Gurukul Group of Institutions, Gandhinagar, Gujarat and Principal & Medical Superintendent. He also served at the Ministry of AYUSH, Government of India as a W.H.O Sponsored Technical Consultant for International Cooperation.",
            "Prof (Dr.) Rao hails from a traditional Ayurveda family of South India. He is an internationally renowned physician, researcher and educator. Over the years he has organized seminars and workshops for practitioners and students in New Zealand, South Africa, Singapore, South Korea, Rwanda, Malawi, Mozambique and Namibia.",
            "He is a Senior NABH Assessor (National Accreditation Board for Hospitals) AYUSH, Quality Council of India, New Delhi and a National External Assessor for NQAS Standards of NHSRC (National Health System Resource Centre, Ministry of Health) for more than a decade. He is also a Member of Ayurveda Training & Accreditation Board (ATAB) set up by the Ministry of AYUSH for all Ayurveda educational programs conducted at foreign countries. As an expert administrator of Open Source Software (Moodle) he promotes e-learning environment in AYUSH domain. As a Technical Consultant (WHO), his expertise included registration of products for export by USFDA / EMEA / UK-MHRA, international market development, establishment of information cell in Indian embassies in foreign countries, international fellowship program for foreign nationals for AYUSH courses. In India, export policy and international regulatory cooperation, intellectual property rights and related WTO issues and health tourism. He has helped successfully to complete the establishment of India's leading 'All India Institute of Ayurveda' a postgraduate education institution with state-of-the-art tertiary care hospital at New Delhi under the Ministry of AYUSH, Government of India. He has the credit of establishing AYUSH Resource Centers in Indian Embassies of many countries.",
            "Prof (Dr.)M. S. Rao presents the modern and scientific face of AYUSH. His practice, backed by clinical research based data, statistics and scientific documentation, makes it unique in AYUSH world. He not only introduced the 'new face' of modern AYUSH, but also responsible for the 'new phase' in AYUSH.",
            "Starting in 1992, he has organized thousands of Ayurvedic & Yoga camps for the people in addition to being actively involved in research activities related to medicinal plant conservation, database building and training. He has travelled extensively in India and had the privilege of conducting consultations, panchakarma programs, lectures and workshops in countries like South Africa, Botswana, Mozambique, Namibia, Rwanda, Malaysia, Singapore, New Zealand, South Korea and Malawi.",
            "He has contributed many scientific papers on Ayurveda and Yoga published in various national and international journals."


        ],
        quote:"Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things.",
        photo:"assets/images/academics/vc.jpg"
    },
    {
        name:" Dr. Jaydatt V. Mehta",
        role:" Deputy Registrar (Admission &amp; Administration)",
        details:[
            "We are pleased to welcome you to the web portal of Swaminarayan University. We the Gurukul Parivar cordially invite you to enrol in the best educational institute where we aim to put our best efforts to raise the level of Quality Education with Sanskar by which the literacy-employment ratio and the economy improve in the area. By Visionary Leadership of Hon’ble Founder President Shastri Swami Premswarupdasji, top administrative management, devoted teachers Swaminarayan University will achieve a sought and renowned position in the galaxy of higher learning in the nation. Our goal is to become a premium hub for research and innovation in the technology era." ,
            "Your time at Swaminarayan University will be one that you remember as being the most rewarding and enjoyable because of the dedication, commitment, and high calibre of our faculty. Our faculty members are dedicated to working with you to support your success and any other academic and professional objectives you may have. We offer affordable tuition fees with applicable scholarships to students from all nations-cultures",
            "We are eager to collaborate with you. Do enjoy the obligations and privileges that come with being our university student.  We hope your academic year will be fruitful and prosperous."
        ],
        quote:"",
        photo:"assets/images/academics/jayduttmehta.jpg"
    },


    {
        name:" Dr. M.N. Patel",
        role:"Advisor, Swaminarayan University",
        details:[
          "Dr. M.N. Patel holds a Ph.D. in Mechanical Engineering and a Bachelor's degree in Civil Structures. With a rich academic and administrative background, he has served as the Vice Chancellor of Gujarat University for three years and as the Principal of L.D. College of Engineering in Ahmedabad, Gujarat for a decade." , 
          "He has also been involved in various prestigious positions such as the Member Secretary of the Admission Committee for Professional Courses in Gujarat, Vice-Chancellor (Officiating) of Gujarat Technological University, and Professor and Principal at Government Engineering College in Modasa.Dr. M.N. Patel has contributed extensively to the educational community, being a member of several governing bodies, including the Association of Indian Universities (AIU) Governing Council, the Governing Body of the Indian Institute of Management in Ahmedabad (IIMA), and the Academic Governing Body of Autonomous colleges such as St. Xavier's Science College and BVM in V.V. Nagar. He has also served on committees for inquiries, promotions, purchase, student transfers, and awards at the state and national levels. His expertise and involvement in the field extend beyond academia, as he has been a member of various professional bodies, including the Inquiry Committee by the University Grants Commission (UGC), the Staff Affairs Committee of the AIU, and the Central Regional Committee of the All India Council for Technical Education (AICTE) in Bhopal." ,
          "Dr. M.N. Patel has also served as the Director of the Board of Studies for Structural Engineering in G.T.U. and participated in the MSME Award Committee of the Industry Department."
        ],
        quote:"",
        photo:"assets/images/academics/m n patil.jpg"
    }

    ,

    {
        name:" Dr. A K Gangawane",
        role:"Registrar",
        details:[
          "Dr. A K Gangawane has completed his Ph.D. in Biochemistry from Mumbai University under a DST funded project in collaboration with BARC, Mumbai and MPKV, Rahuri. He has completed M.Sc. (Biochemistry) from SIES College, Mumbai, and B.Sc. (Biochemistry &amp; Chemistry) from Somaiya College, Mumbai. He is Recipient of Department of Science and Technology, New Delhi –Fellowship (Govt. of India). Currently he is serving as Registrar, Swaminarayan University.  He has 75 full length paper, 23. Gen Bank Submission and 45 paper in international conference proceeding. Dr Gangawane has 3 textbooks and 2 book chapters to his credit and has a about 21 years’ experience in field of research teaching and academic administration. He has attended about 20 programmes related to higher education policy organized by various organization. He has a Life member for Immunology Group- ACTREC, TMC, Kharghar, and Society for Free Radical Research – INDIA (Affiliated to SFRR- Asia/International). He has guided 8 PhD students, 5 M.Tech, 8 B.Tech, 13 Msc and 3 groups of Final Year BE students.", 
          "Research Area: Plant Biochemistry, Molecular Biology, Antioxidants, Free radical Research, Bioinformatics and Genetic disorders."
        ],
        quote:"Learning gives creativity, creativity leads to thinking, thinking provides knowledge,and knowledge makes you great",
        photo:"https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/ajit-sir-b.jpg"
    }
,
    
]