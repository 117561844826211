import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function RulesPharmacy() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>Rules & Regulations</h3>
            <p>Rules and Rugulations documents Link are below</p>
          </div>

          <div className="container">
            <div className="alumni-area pt-100 pb-70">
              <div className="row">
                <div className="col-md-4">
                  <a
                    // href="../assets/pharmacy/documents/rules-regulations-b-pharm.pdf"
                    href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1723094394431.pdf"
                    target="_blank"
                  >
                    <div className="single-alumoni-updates-card">
                      <div className=" d-flex flex-row flex-wrap align-items-center">
                        <div className=" bg-danger text-center p-3 me-4">
                          <i
                            class="ri-file-download-line text-light "
                            style={{ fontSize: "20px" }}
                          ></i>
                        </div>
                        <div className="">
                          <p>B.Pharm Rules and Regulations</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-md-4">
                  <a
                    // href="../assets/pharmacy/documents/rules-regulations-m-pharm.pdf"
                    href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1723094583964.pdf"
                    target="_blank"
                  >
                    <div className="single-alumoni-updates-card">
                      <div className=" d-flex flex-row flex-wrap align-items-center ">
                        <div className=" bg-danger text-center p-3 me-4">
                          <i
                            class="ri-file-download-line text-light "
                            style={{ fontSize: "20px" }}
                          ></i>
                        </div>
                        <div className="">
                          <p>M.Pharm Rules & Regulations</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-md-4">
                  <a
                    // href="/assets/pharmacy/documents/rules-regulations-pharm-d.pdf"
                    href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1723094604109.pdf"
                    target="_blank"
                  >
                    <div className="single-alumoni-updates-card">
                      <div className=" d-flex flex-row flex-wrap align-items-center ">
                        <div className=" bg-danger text-center p-3 me-4">
                          <i
                            class="ri-file-download-line text-light "
                            style={{ fontSize: "20px" }}
                          ></i>
                        </div>
                        <div className="">
                          <p>Pharm.D. Rules & Regulations</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default RulesPharmacy;
