import React from 'react'

function Citizencharter() {
    const banner1 = 'https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/images/DJI_0449.JPG'
    const banner2 = 'https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/images/DJI_0373.JPG'
   
  return (
    <div className='Infrastructure mt-5'>
    <div className="academics-details-area pt-90 pb-70">
<div className="container">
<div className="row">
  <div className="col-lg-12">
    <div className="academics-details">
      <div className="details">
        <h2  className='text-center'>CITIZEN’S CHARTER</h2>
        <h4 className='text-center'>PSM Hospital, Kalol attached to Swaminarayan
Institute of Medical Sciences and Research, Kalol</h4>
        <hr />
        <h4>Preamble:</h4>

        <p style={{ textAlign: "justify" }}>
     <ol>
        <li>
        Swaminarayan Institute of Medical Sciences and Research, Kalol
provides quality health care services through PSM Hospital. The
details of hospital services are written in this citizen’s charter.
        </li>
        <li>Through this Charter the Citizens are also informed about the services
are available, the quality of the care provided and grievance redressed
procedures at PSM Hospital.</li>
     </ol>
        </p>
        <h4>
        Quality of Services:
        </h4>
        <p style={{ textAlign: "justify" }}>
        PSM Hospital attached to Swaminarayan Institute of Medical Sciences and
Research, Kalol is tertiary care hospital which provides high quality service to
each patient without any discrimination.
        </p>

        <h4>
        General Information:
        </h4>

        <p style={{ textAlign: "justify" }}>PSM Hospital has 390+ beds with competent doctors and Nursing staff.</p>
        <h4>Available Services:</h4>
        <ol>
            <li>
            Outpatient Services
            </li>
            <li>Indoor Services</li>
            <li>Emergency services (24 x 7)</li>
            <li>Operations</li>
            <li>Intensive care units</li>
            <li>Laboratory Services</li>
            <li>X-Ray and USG</li>
            <li>Pharmacy</li>  
            <li>Blood Bank</li> 
            <li>Ambulance Services</li>   
            
              </ol>
        <div className="row">
            <div className="col-md-4">
                <h4>
                OPD Timings:
                </h4>
                <p>9 AM to 5 PM</p>
            </div>
            <div className="col-md-4">
                <h4>Indoor Services:</h4>
                <p>Nursing staff and on duty doctors services are available in wards 24x7.</p>
            </div>
            <div className="col-md-4">
                <h4>Operation theatres:</h4>
                <p>There are 4 Major, 4 minor and 1 emergency OT available in Hospital.</p>
            </div>

            <div className="col-md-6">
                <h4>
                Emergency services (24 x 7):
                </h4>
                <p>Provided by on duty doctors and nursing staff. <br />
All equipments and drugs available in Emergency room. <br />
Emergency cases are attended on priority. <br />
Emergency Operation theatre also available.</p>
            </div>
            <div className="col-md-6">
                <h4>Laboratory Services:</h4>
                <p>Available for Hematology, Biochemistry, Microbiology, Histopathology and
Cytopathology.</p>
            </div>
            <div className="col-md-6">
                <h4>Blood Bank Services:</h4>
                <p>Blood bank services available round the clock.</p>
            </div>
            <div className="col-md-6">
                <h4>Ambulance Services:</h4>
                <ol>
                    <li>PSM Hospital has 3 Ambulances.</li>
                    <li>Facility is available 24 hours.</li>
                </ol>
            </div>
            <div className="col-md-6">
                <h4>General Instructions:</h4>
                <ol><li>
                PSM hospital is a “No Smoking” and “No spitting” zone.</li>
                <li>Please stand in queue and follow the instructions given by support staff.</li>
                <li>Don’t talk loudly.</li>
                <li>Avoid making crowds.</li>
                <li>Keep our Hospital clean.</li></ol>
            </div>
            <div className="col-md-6">
                <h4>Complains and Grievances:</h4>
                <ol>
                    <li>
                    We try to provide best services to patients.
                    </li>
                    <li>In case we do not meet expectations then Register complains in
complains/feedback Register available at Reception.</li>
<li>However, Complains or Feedback can be directly given to Medical
Superintendent.</li>
                </ol>
            </div>
        </div>

        <h4 className='mt-4'>Contact Us:</h4>
        <p>
<b>Address:</b> PSM Hospital, Ahmedabad Mehsana Highway  <br /> 
At &amp; Post: Saij 
Ta: Kalol 
<b>Dist.:</b> Gandhinagar
<br />
<b>State: </b>Gujarat 
<b>Pin: </b>382725 <br /> 
<b>Email :</b>
<a href="mailto:psmhkalol2014@gmail.com">psmhkalol2014@gmail.com</a> <br />
<b>Phone :</b> 9638052525, 8866213131 </p>
        <div className="details-images">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <img
                src={banner1}
                alt="Gurukul Top View"
                height="100%"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <img
                src={banner2}
                alt="Universiry Front View"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
     
    </div>
  </div>
</div>
</div>
</div>

</div>
  )
}

export default Citizencharter