import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./../../Router/RouterConfig";
import { profileData } from "./../../Pages/About/AutoritiesOfUniversity/ProfileData";
import "./Navbar.scss";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import {
  ACADEMIC_CALENDAR_GET,
  ADD_COMMITTEES,
  COLLEGE_PERMISSION_GET,
  STAFF_DETAILS_NEW_GET,
} from "../../Utils/InfoUploadingConst";

function Navbar2() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [lettersdata, setLettersData] = useState([]);
  const [staffdata, setStaffData] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_COMMITTEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111007
        );
        console.log("activeData -", activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: ACADEMIC_CALENDAR_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        // res.data.data.sort((a, b) => b.session - a.session);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111007
        );
        setData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: COLLEGE_PERMISSION_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        // console.log(res.data.data)
        // setLettersData(res.data.data);

        const Permission = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111007
        );

        setLettersData(Permission);
      })
      .catch((err) => {
        console.log(err);
      });

    const config4 = {
      method: "get",
      url: STAFF_DETAILS_NEW_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config4)
      .then((res) => {
        // res.data.data.forEach((element) => {
        //   element.attachment = JSON.parse(element.attachment);
        // });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        // const feeData = res.data.data.filter(
        //   (element) =>
        //     element.status === "ACTIVE" && element.college_id === 1111007
        // );
        const feeData = res.data.data.filter((element) => {
          // Check if attachment is in a JSON-stringified format and parse it
          if (
            typeof element.attachment === "string" &&
            element.attachment.startsWith('"') &&
            element.attachment.endsWith('"')
          ) {
            element.attachment = JSON.parse(element.attachment);
          }

          // Filter the data based on the status and college_id
          return element.status === "ACTIVE" && element.college_id === 1111000;
        });
        setStaffData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Navbar2">
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-4">
              <div className="header-right-content">
                <marquee className="scroll-text text-white">
                  <p className="text-white">
                    Toll free number to report ragging :{" "}
                    <a href="tel:1800 843 2900" className="text-light">
                      1800 843 2900
                    </a>{" "}
                  </p>
                </marquee>
              </div>
            </div>

            <div className="col-lg-8 col-sm-8">
              <div className="header-right-content">
                <div className="list">
                  <ul>
                    <li className="nav-item">
                      <Link
                        to={"/Homoeopathy/Recruitment"}
                        className="nav-link"
                      >
                        Recruitment
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://ums.swaminarayanuniversity.ac.in/"
                        target="_blank"
                      >
                        UMS Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="navbar-area nav-bg-1">
          <div className="mobile-responsive-nav">
            <div className="container">
              <div className="mobile-responsive-menu">
                <div className="logo">
                  <a href="/Homoeopathy">
                    <img
                      src="/assets/images/homoeopathy logo.png"
                      className="main-logo"
                      lt="logo"
                    />
                    <img
                      src="/assets/images/homoeopathy logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/Homoeopathy">
                  {/* <img  src="/assets/images/homoeopathy logo.png" width="150px" alt="logo" /> */}
                  <img
                    src="/assets/images/white-logo.png"
                    width="250px"
                    alt="logo"
                  />
                </a>
                <h6 class="text-center">
                  Shree Swaminarayan Homoeopathy College, Kalol
                </h6>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link to={"/Homoeopathy"}>Home</Link>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Overview"
                      >
                        Overview <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/AboutUs"}>About SSVG</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/FoundersMessage"}>
                            Founder Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/PrincipalMessage"}>
                            Principal Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/MissionVision"}>
                            Mission Vision
                          </Link>
                        </li>
                        <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Office Bearers <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              {/* <a
                          onClick={()=>{changeDir(profileData[0])}}
                          className="nav-link custom-div-link"
                        >
                          President
                        </a> */}
                              <Link
                                to={"/Homoeopathy/President"}
                                className="nav-link"
                              >
                                President
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <Link to={'/VicePresidents'} className="nav-link">
                          Vice Presidents
                        </Link> */}
                              <Link
                                to={"/Homoeopathy/VicePresidentMsg"}
                                className="nav-link"
                              >
                                Vice Presidents
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                          onClick={()=>{changeDir(profileData[4])}} className="nav-link"
                        
                        >
                          Provost (Vice-Chancellor)
                          </a> */}
                              <Link
                                to={"/Homoeopathy/Provost"}
                                className="nav-link"
                              >
                                Provost (Vice-Chancellor)
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                          onClick={()=>{changeDir(profileData[7])}}
                          className="nav-link custom-div-link"
                        >
                          Registrar
                        </a> */}
                              <Link
                                to="/Homoeopathy/Registrar-Profile"
                                className="nav-link"
                              >
                                Registrar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="College"
                      >
                        College <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/AboutCollege"}>
                            About College
                          </Link>
                        </li>
                        {/* <li className="nav-item">
            
              <Link to={"/Homoeopathy/CollegeCouncil"}>College Council</Link>
             
            </li> */}
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/Recognition"}>NOC</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Homoeopathy/Permission" title="Permission">
                            Permission / Recognition
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/Homoeopathy/Affiliation"
                            title="Affiliation"
                          >
                            Affiliation
                          </Link>
                        </li>

                        {/* <li className="nav-item">
             <Link to={"/Homoeopathy/Course"}>Courses</Link>
            </li> */}

                        {/* <li className="nav-item">
              <a href="#" title="Facilities">
                Facilities
              </a>
            </li>
         <li className="nav-item nav-item">
              <a href="#" title="Departments">
                Departments
              </a>
            </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Academics <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/Admissions"}>
                            {" "}
                            Admissions details with Eligibility Criteria
                          </Link>
                        </li>

                        {data?.filter((d) => d.type === "Fee Structure")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Fee Structure")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Fee Structure
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              Fee Structure
                            </a>
                          </li>
                        )}

                        {/* <li className="nav-item">
         <Link to={"/Homoeopathy/FeeStructure"}> Fee Structure</Link>

            </li> */}

                        {data?.filter((d) => d.type === "Courses Details")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Courses Details")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Courses
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              Courses
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Introduction+to+CBDC.pdf"
                            target="_blank"
                          >
                            CBDC First BHMS
                          </a>
                        </li>

                        {/* {lettersdata?.filter((d) => d.type === "Reg Letter").length !== 0 ? (
                            lettersdata
                            ?.filter((d) => d.type === "Reg Letter")
                            ?.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <a href={item?.attachments} target="_blank" className="nav-link">
                                Reg. Letter
                              </a>
                            </li>
                            ))
                              ) 
                              : 
                                <li className="nav-item" >
                                  <Link to="/Homoeopathy/">
                                    Reg. Letter
                                  </Link>
                                </li>
                          } */}

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/Rules"}>
                            {" "}
                            Rules & Regulations{" "}
                          </Link>
                        </li>

                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Committees
                          </a>
                          <ul className="dropdown-menu">
                            {info.filter((d) => d.type === "College Council")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "College Council")
                                .map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      College Council
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  College Council
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Curriculum Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Curriculum Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Curriculum Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Curriculum Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Internal Complaint Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Internal Complaint Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Internal Complaint Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Internal Complaint Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) =>
                                d.type ===
                                "Hospital Infection Control Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type ===
                                    "Hospital Infection Control Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Hospital Infection Control Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Hospital Infection Control Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Anti-Ragging Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Anti-Ragging Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Anti-Ragging Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Anti-Ragging Committee
                                </a>
                              </li>
                            )}

                            {info.filter((d) => d.type === "Ethics Committee")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "Ethics Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Ethics Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Ethics Committee
                                </a>
                              </li>
                            )}

                            {/* {info.filter((d) => d.type === "Pharmacovigilance Committee").length !== 0 ? (
                             info
                              .filter((d) => d.type === "Pharmacovigilance Committee")
                              ?.map((item, index) => (
                                <li className="nav-item" key={index}>
                                  <a href={item?.attachment} target="_blank" className="nav-link">
                                  Pharmacovigilance Committee
                                  </a>
                                </li>
                              ))
                              )
                              :
                              <li className="nav-item" >
                                <a href="" target="_blank" className="nav-link">
                                Pharmacovigilance Committee
                                </a>
                              </li>
                            } */}

                            {info.filter(
                              (d) => d.type === "Scientific review Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Scientific review Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Scientific review Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Scientific review Committee
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/Syllabus"}>Syllabus</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/studentList"}>
                            Students List
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/resultsList"}>
                            Result Analysis
                          </Link>
                        </li>

                        {/* <li className="nav-item">
              <a href="#" title="Achievements">
                Achievements
              </a>
            </li> */}

                        <li className="nav-item">
                          <Link to="/Homoeopathy/AcademicCalendar">
                            {" "}
                            Academic Calender
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Staff"
                      >
                        NCH Disclosure <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nch-disclosuress">
                        <li className="nav-item">
                          <Link
                            to={
                              "/Homoeopathy/Details_Of_Medical_Superintendent"
                            }
                          >
                            a) Details of Director/Principal/ Medical
                            Superintendent
                          </Link>
                        </li>

                        <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Academics"
                          >
                            b) Affiliating University
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link
                                to={"/Homoeopathy/ViceChancellor"}
                                className="nav-link"
                              >
                                Vice-Chancellor
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/Homoeopathy/Registrar"
                                className="nav-link"
                              >
                                Registrar
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/Homoeopathy/PrincipalMessage"
                                className="nav-link"
                              >
                                Dean/ Head of Faculty of Homoeopathy
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/PrincipalSecretary"}>
                            c) Principal Secretary/ Secretary and commissioner /
                            Director of AYUSH or Homoeopathy
                          </Link>
                        </li>

                        {/* <li className="nav-item">
              <Link to={"/Homoeopathy/teachingStaff"}>d)  Teaching Staff</Link>
            </li> */}

                        {/* <li className="nav-item">
              <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/undefined_1713952998814.pdf" target='_blank'>
              e)  Non-Teaching Staff
              </a> 
            </li>        */}

                        {staffdata?.filter(
                          (d) => d.emp_category === "TEACHING STAFF"
                        ).length !== 0 ? (
                          staffdata
                            ?.filter((d) => d.emp_category === "TEACHING STAFF")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  d) Teaching Staff
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/Homoeopathy/"
                              target="_blank"
                              className="nav-link"
                            >
                              d) Teaching Staff
                            </a>
                          </li>
                        )}

                        {staffdata?.filter(
                          (d) => d.emp_category === "NON-TEACHING STAFF"
                        ).length !== 0 ? (
                          staffdata
                            ?.filter(
                              (d) => d.emp_category === "NON-TEACHING STAFF"
                            )
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  e) Non-Teaching Staff
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/Homoeopathy/"
                              target="_blank"
                              className="nav-link"
                            >
                              e) Non-Teaching Staff
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/undefined_1713952998031.pdf"
                            target="_blank"
                          >
                            f) Hospital Staff
                          </a>
                          {/* <Link 
              to="/Homoeopathy/HospitalStaff"
              
              >{" "} e)  Hospital Staff</Link> */}
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/Permission"}>
                            g) Recognition / Permission
                          </Link>
                        </li>

                        {/* <li className="nav-item">
              <Link to={"/Homoeopathy"}>h)  Sanctioned intake capacity of BHMS course</Link>
            </li> */}

                        {data?.filter((d) => d.type === "Courses Details")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Courses Details")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  h) Sanctioned intake capacity of BHMS course
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              h) Sanctioned intake capacity of BHMS course
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/studentList"}>
                            i) List of students
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/ResearchandPublication"}>
                            j) Research publication: (last 5 year)
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy"}>
                            k) Continuous Medical Education (Research
                            course/conference/academic activity)
                          </Link>
                        </li>

                        {/* <li className="nav-item">
              <Link to={"/Homoeopathy"}>l)  Awards and Achievements</Link>
            </li> */}

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/Achievements-Awards-Medals"}>
                            {" "}
                            l) Awards and Achievements{" "}
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/resultsList"}>
                            m) Result of examination (last 1 year)
                          </Link>
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/Homoeopathy"}>
                            n) Details of fees of course and other costs
                          </Link>
                        </li> */}

                        {data?.filter((d) => d.type === "Fee Structure")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Fee Structure")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  n) Details of fees of course and other costs
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              n) Details of fees of course and other costs
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/OPD"}>
                            o) Statistics of OPD of last five years
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/IPD"}>
                            p) Statistics of IPD of last five years
                          </Link>
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/Homoeopathy"}>
                            q) Recording of registration of OPD & Dispensing
                            Area
                          </Link>
                        </li> */}

                        <li className="nav-item">
                          <Link
                            to={"/Homoeopathy/Activities_of_Ministry_of_Ayush"}
                          >
                            q) Activities of Ministry of Ayush
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            to={
                              "/Homoeopathy/Link_for_all_related_bodies_of_the_Central_government_state_government_and_union_territory_Administration"
                            }
                          >
                            r) Link for all related bodies of the Central
                            government , state government and union territory
                            Administration
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/NodalOfficer"}>
                            s) Nodal Officer
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/NodalOfficer"}>
                            t) Contact details of Nodal Officer
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            to={
                              "/Homoeopathy/Other_Information_by_the_Commission"
                            }
                          >
                            u) Any other information By the Commission
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Hospital"
                      >
                        Hospital <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/AboutHospital"}>
                            About Hospital
                          </Link>
                        </li>

                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/undefined_1713952998031.pdf"
                            target="_blank"
                          >
                            Hospital Staff
                          </a>

                          {/* <Link to="/Homoeopathy/HospitalStaff">
                Hospital Staff
              </Link> */}
                        </li>

                        {/* <li className="nav-item">
              <a href='https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Staff/Other+Hospital+Staff.pdf' target='_blank'>
                Other Hospital Staff
              </a>
            </li> */}

                        {/* <li className="nav-item">
              <Link to={"/Homoeopathy/Hospital-Staff-Attendance"}>Hospital Staff Attendance</Link>
            </li> */}

                        <li className="nav-item">
                          <Link to={"/Homoeopathy/OPD"}>OPD Data</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Homoeopathy/IPD"}>IPD Data</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link to={"/Homoeopathy/Gallery"}>Gallery</Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/Homoeopathy/events"}>Events</Link>
                    </li>
                    {/* <li className="nav-item">
          <Link to={'/Homoeopathy/ResearchandPublication'} >
            Research and Publication
          </Link>
        </li> */}
                    <li className="nav-item">
                      <Link to={"/Homoeopathy/ContactUs"}>Contact Us</Link>
                    </li>
                    <li className="nav-item">
                      <a href="#" title="Member Menu">
                        <i className="glyphicon glyphicon-user" />
                      </a>
                    </li>{" "}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar2;
