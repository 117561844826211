import React from 'react'

const url ="/assets/pdf/.pdf"

function Act() {
  return (
    <div className='Act'>
        <div className="faq-area ptb-80">
  <div className="container">
    <div className="row align-items-center">
      <h2 className="text-center">Act, Statutes &amp; Ordinances</h2>
      <div className="col-lg-6">
        <div className="faq-image pr-20">
          {/* <h2>Act, Statutes &amp; Ordinances</h2> */}
          <hr />
          <object
            height={800}
            data="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/act.pdf#toolbar=0"
            type="application/pdf"
            width="100%"
          >
            <p>
              It appears you don't have a PDF plugin for this browser. No
              biggie... you can{" "}
              <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/act.pdf#toolbar=0">
                click here to download the PDF file.
              </a>
            </p>
          </object>
        </div>
        </div>
        <div className="col-lg-6">
        <div className="faq-image pr-20">
          {/* <h2>Act, Statutes &amp; Ordinances</h2> */}
          <hr />
          <object
            height={800}
            data="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/eli.pdf#toolbar=0"
            type="application/pdf"
            width="100%"
          >
          {/* <embed
            src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/eli.pdf#toolbar=0"
            width="100%"
            height="500px"
          /> */}
            <p>
              It appears you don't have a PDF plugin for this browser. No
              biggie... you can{" "}
              <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/act.pdf#toolbar=0">
                click here to download the PDF file.
              </a>
            </p>
          </object>  
          
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Act