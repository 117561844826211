import React from 'react'

function AboutCollege() {
  return (
    <div><>
    {/* Start For Content */}
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-3">
        
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637632899_2902-org.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={261}
              height={261}
            />
            <br />
            <br />

       
       
        </div>
        <div className="col-md-9">
          <h2>
          About College
            <br />
          </h2>
          <p style={{ textAlign: "justify" }}>
      
          Shree Swaminarayan Science College is an esteemed educational establishment dedicated to the delivery of education and training in diverse scientific fields. This institution places a significant emphasis on fostering academic excellence and research in the realms of Chemistry, Microbiology, Botany, Zoology, Biotechnology, Physics, Mathematics, and Environmental Science. Through its comprehensive academic offerings, this college provides an extensive array of educational opportunities for students, spanning from undergraduate programs to postgraduate studies and even opportunities for advanced research through Ph.D. programs. The institution's multifaceted approach to education ensures that students are not only equipped with theoretical knowledge but also empowered with practical skills, enabling them to make significant contributions to the scientific community and society at large. Shree Swaminarayan Science College's commitment to fostering a deep understanding of the natural world and promoting scientific inquiry is a testament to its dedication to producing well-rounded and capable graduates who can address complex challenges and make meaningful advancements in their chosen scientific fields.
          </p>
          
        </div>
      </div>
      <br />
      <br />
    </div>{" "}
    {/* End For Content */}
    
  </>
  </div>
  )
}

export default AboutCollege