import React from "react";
import '../Arts/OfficerProfile.scss'

// const principalImage = "/assets/arts-commerce/images/rupam-mishra.jpg";
const principalImage = "https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1702010240865.jpeg";


function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <br/>
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail commerce-princi"
                width={300}
                // height={700}
              />
              <br />
              <br />

              <h3>
                Dr. Ashish Rami
                <br />
              </h3>
              <p style={{}}>
                Dean, Faculty of Commerce and  Management 
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Welcome to our esteemed university! As the Principal, we are
                proud to share our unwavering commitment to academic excellence
                and holistic student development.
                <br />
                <br />
                Our comprehensive curriculum, cutting-edge facilities, and
                innovative teaching methodologies ensure you receive a
                world-class education that prepares you for success. Our
                distinguished faculty members are not only accomplished
                teachers, but also mentors who are deeply invested in your
                academic and personal growth.
                <br />
                <br />
                Beyond academics, our campus promotes values such as integrity,
                compassion, and leadership, through extracurricular activities,
                clubs, and sports. Our diverse student body provides rich
                opportunities for engaging in meaningful discussions and
                collaborative projects, fostering cross-cultural interactions.
                <br />
                <br />
                Our university is also dedicated to research and innovation,
                providing opportunities for you to participate in research
                projects, internships, and experiential learning. In addition,
                we prioritize your overall well-being and offer comprehensive
                support services, including counselling, health services, and
                wellness programs.
                <br />
                <br />
                Join our community of scholars, thinkers, and leaders, where you
                will receive a rigorous and enriching education that prepares
                you for your future career while fostering personal growth,
                character development, and social responsibility.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
