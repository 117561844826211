import React from 'react'

function AboutPhd() {
  return (
    <div className='AboutPhd'>
        <div className="faq-area ptb-100">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="faq-left-content pl-20">
          <div className="faq-title">
            <h2>About Ph.D Programs at Swaminarayan Universisty</h2>
            <p style={{ textIndent: "2rem", textAlign: "justify" }}>
              Universities engage in research as part of their missions around
              learning and discovery. This can come in the form of fundamental
              or applied research. Ph.D degree typically involves students
              independently conducting original and significant research in a
              specific field or subject, before producing a publication-worthy
              thesis.
            </p>
            <br />
            <p style={{ textIndent: "2rem", textAlign: "justify" }}>
              Research Policy of Swaminarayan University has adopted the
              approach to create and support a research culture for developing
              and promoting scientific temper and research aptitudes among its
              teachers, staff and students. Research wing of Swaminarayan
              University is tasked with strategic academic and professional
              objectives. Most are highly specialised and are organised into
              appropriate networks based on the subjects they pursue.
            </p>
            <h2>Contact for more info</h2>
            <p>
            **Department of Research:** <br />
- Email Address: rd@swaminarayanuniversity.ac.in <br />
- Mobile Numbers: 9176945090, 9345891984 <br />
- Dean Research (I/C): Dr. Anita Patel <br />
            </p>

          <p>  For HR Department Contact: <br />
- Ms. Monali Prajapati <br />
  - Email Address: office.registrar@swaminarayanuniversity.ac.in <br />
  - Mobile Number: 9737001727</p>

          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="faq-image pr-20">
          <h2>Eligiblity</h2>
          <hr />
          <object
            height={500}
            data="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/eli.pdf#toolbar=0"
            type="application/pdf"
            width="100%"
          >
            <p>
              It appears you don't have a PDF plugin for this browser. No
              biggie... you can{" "}
              <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/eli.pdf#toolbar=0">
                click here to download the PDF file.
              </a>
            </p>
          </object>
          {/*<embed src="assets/pdf/eli.pdf#toolbar=0" width="100%" height="500px">*/}
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default AboutPhd