import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ACHIEVEMENTS_GET } from "../Utils/InfoUploadingConst";

function Circular() {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
    const config = {
      method: "get",
      url: ACHIEVEMENTS_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        setData(res.data.data.filter((item) => item.type === "Circular"));
        console.log("data -", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="container mt-3">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>CIRCULARS LIST</h3>
            <p>Circulars List documents Links are below</p>
          </div>

          <div className="alumni-area mt-4">
            <div className="row">
              {data
                ?.filter(
                  (item) =>
                    item.status === "ACTIVE" && item.college_id === 1111012
                )
                ?.map((item, key) => (
                  <div className="col-md-4">
                    <a href={item?.attachments} target="_blank">
                      <div className="single-alumoni-updates-card">
                        <div className=" d-flex flex-row align-items-center ">
                          <div className=" bg-danger text-center p-3 me-3">
                            <i
                              class="ri-file-download-line text-light "
                              style={{ fontSize: "20px" }}
                            ></i>
                          </div>
                          <div className="">
                            <h5>{item?.title}</h5>
                            <p className="text-right">
                              {/* {item?.date.substring(0, 10)} */}
                              {item?.date
                                ? `${item.date.substring(
                                    8,
                                    10
                                  )}-${item.date.substring(
                                    5,
                                    7
                                  )}-${item.date.substring(0, 4)}`
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Circular;
