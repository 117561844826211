import React from "react";

function FoundersMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637520321_2426-org.jpg"
                alt=""
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <p style={{}}>
                <strong>
                  Shastri Swami Premswarup Das Ji
                  <br />
                </strong>
                Founder and Managing Trustee
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Founder's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                I extend my warm wishes and welcome you all to Shree
                Swaminarayan Information Technology & Computer Science College,
                (SSITCSC) Kalol, Gandhinagar established in 2017 for the service
                of rural population of Kalol. The college is not just a platform
                for delivering IT and Computer Science education, but also a
                nurturing environment to produce honest, dynamic, and competent
                individuals prepared to embrace the significant responsibilities
                of IT and Computer Science professionals. Our institution is
                gaining recognition for its comprehensive quality education,
                thanks to visionary leadership, dedicated faculty, continually
                expanding resources, a strong commitment to student development,
                and an insatiable drive for improvement and growth. At SSITCSC,
                we emphasize discipline, strict student attendance, the
                cultivation of moral character, the use of cutting-edge
                audiovisual teaching methods, and engagement in extracurricular
                activities. Our departments are well-equipped with
                state-of-the-art technology, models, charts, and specimens. We
                are striving to establish this institution as a model center for
                Information Technology and Computer Science. Each new academic
                session brings new advancements to maintain our high standards.
                We offer numerous platforms for individuals to explore,
                showcase, and express their ideas. Students have the opportunity
                to interact with and train in the IT and Computer Science
                sector, opening up a world of opportunities, including
                employment at leading technology centers. Our commitment to our
                vision and the dedication of our staff and students position
                SSITCSC for success in the ever-evolving and demanding world of
                information technology and computer science. Let us work
                together to build a bright career in the noble profession of IT
                and Computer Science, which serves humanity through
                technological advancements, in the spirit of Dr. Hahnemann.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default FoundersMessage;
