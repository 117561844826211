import React from 'react'

function FoundersMessage() {
  return (
    <div><>
    {/* Start For Content */}
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-3">
        
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637520321_2426-org.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={350}
              height={261}
            />
            <br />
            <br />

       
          <p style={{  }}>
            <strong className="ml-5">
              Shastri Swami Premswarup Das Ji
              <br />
            </strong>
            Founder and Managing Trustee
          </p>
        </div>
        <div className="col-md-9">
          <h2>
            Founder's Message
            <br />
          </h2>
          <p style={{ textAlign: "justify" }}>
            I extend my warm wishes and welcome you all to Shree Swaminarayan
            Science College, (SSSC) Kalol, Gandhinagar
            established in 2017 for the service of rural population of Kalol The
            college is not only a medium for imparting science education but also
            as molding ground to turn out honest, dynamic competent individuals
            ready to take the handsome responsibility as a science professional.
            Institute is turning out to be a reputed frame with all-round quality
            learning due to great leadership, dedicated faculty, ever-increasing
            facilities, deep concern for student’s development and an insatiable
            urge to improve and expand. We at SSSC believe in discipline, strict
            student attendance, the teaching of moral character, the latest
            audiovisual mode of teaching with extracurricular activities.
            Departments are fully versed with basic as well as the latest
            equipment, models, charts, specimens. We are trying to make this
            institute as a model institute of Homeopathy. With each new session,
            new developments are accompanied to maintain a standard. For each
            individual, we provide numerous platforms where they can explore the
            exhibit and express their ideas. They can interact and train with the
            medical sector thus opening for them a world of opportunities where
            they get employment at the nation’s leading health centers. Our
            commitment to our vision and dedication of our staff and students
            ensures that SSSC is well poised for success in the ever-demanding
            and changing world of technology. Let us join to make a bright carrier
            in the noble profession of Homoeopathy, which dignifies in the service
            to sick, humanity by way of Dr Hahnemann.
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>{" "}
    {/* End For Content */}
    
  </>
  </div>
  )
}

export default FoundersMessage
