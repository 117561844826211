import React from "react";
import "./Profiles.scss";

function Deans() {
  return (
    <div className="Deans VicePresidents">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">Deans</h1>
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/ravipatel.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Ravikumar Patel</h6>
                            <p className="designation">
                              {" "}
                              Dean, Faculty of Pharmacy
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/rohit.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Rohit Gokarn</h6>
                            <p className="designation">
                              {" "}
                              Dean, Faculty of Ayurveda
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/Trilok.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Prof (Dr.) Trilok Sompura</h6>
                            <p className="designation">
                              {" "}
                              Dean, Faculty of Nursing
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/dinesh rao.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Denesh Rao</h6>
                            <p className="designation">
                              {" "}
                              Faculty of Homoeopathy
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/dixit.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Dixit Patel</h6>
                            <p className="designation">
                              {" "}
                              I/C Dean, Faculty of LAW
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/ravikumar.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Prof. Ravikumar.K </h6>
                            <p className="designation">
                              {" "}
                              Dean, Faculty of Engineering
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/pradeip.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Pradeep Verma</h6>
                            <p className="designation">
                              Dean, Faculty of Science
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/nitin.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Nitin Raval</h6>
                            <p className="designation">
                              Dean, Faculty of Education
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1702010240865.jpeg"
                              alt="Members"
                              width="100%"
                              height="380px"
                            />
                          </div>

                          <div className="single_advisor_details_info">
                            <h6>Dr. Ashish Rami </h6>
                            <p className="designation">
                              Dean, Faculty of Commerce and Management
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/ayurvedic/dean.jpg"
                              alt="Members"
                              width="100%"
                            />
                          </div>

                          <div className="single_advisor_details_info">
                            <h6>Dr. Ashvin Sangavi </h6>
                            <p className="designation">
                              Dean, Faculty of Medicine
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="advisor_thumb">
                            <img
                              // src="../assets/images/academics/geetanjali.jpg"
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1701843038922.26"
                              alt="Members"
                              width="100%"
                              height="400px"
                            />
                          </div>

                          <div className="single_advisor_details_info">
                            <h6> Dr. Geetanjali Amarawat </h6>
                            <p className="designation">
                              Dean, Faculty of IT and CS
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1706163079559.32"
                              alt="Members"
                              width="100%"
                              height="380px"
                            />
                          </div>

                          <div className="single_advisor_details_info">
                            <h6>Dr. Maulik Shah</h6>
                            <p className="designation">
                              Dean, Faculty of Design & Communication
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/jani.jpg"
                              alt="Members"
                              width="100%"
                              height="380px"
                            />
                          </div>

                          <div className="single_advisor_details_info">
                            <h6>Dr. Jitendra Jani </h6>
                            <p className="designation">
                              I/C Dean, Faculty of Arts & Humanities
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Urvashi+Jadeja.png"
                              alt="Members"
                              width="100%"
                              height="380px"
                            />
                          </div>

                          <div className="single_advisor_details_info">
                            <h6> Dr. Urvashi Jadeja </h6>
                            <p className="designation">
                              I/C Dean, Faculty of Physiotherapy
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deans;
