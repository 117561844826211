import React from 'react'

function DeanMedicalSuperintendent() {

  return (
    <div>
        <div className="container p-4">
            <div className="row d-flex align-items-center">
                <div className="col-md-12">
                    <h2 className='text-center'>Details of Dean</h2>
                    <hr />
                </div>
                <div className="col-md-6">
                
          
          <img src="../assets/images/deannew.jpg" className='img-fluid rounded shadow-sm' style={{ height: '500px', width: 'auto' }} />
         <div className="text-center mt-3">
         
         </div>
                </div>
                <div className="col-md-6">
                   <div className="">
                    <br/>
                    <br/>
                   <h1>Dr. Ashwin Sanghavi</h1>
                    <h5>M.B.B.S, M.D (Paediatrics), F.I.A.P.</h5>
                      <p> Swaminarayan Institute of Medical Sciences and Research, Kalol.</p>
                      <p>Designation : Dean</p>
                      <p>Joining Date : 01/10/2022</p>
                      <p>Email:  <a href="mailto:deanoffice.sims@swaminarayanuniversity.ac.in">deanoffice.sims@swaminarayanuniversity.ac.in</a></p>
                      <p>Reg. No. (UG/PG)	:	G-10735/G-2047</p>
                      <p>Qualification		:	MD (Pediatrics)</p>
                      <p>Teaching Experience	:	16 years 1 month</p>
                      <p>Address			:	201 Binori 180 opp Ambience tower chief Justice 
                                                Bungalow Road Bodakdev, Ahmedabad- 380054, Gujarat
                      </p>
                      <p>Phone: <a href="tel:+91-91141 39114">+91-91141 39114</a></p>
                   </div>

                </div>
            </div>
            <br/>
            <br/>
            <div className="row d-flex align-items-center mt-5">
                <div className="col-md-12">
                    <h2 className='text-center'>Details of Medical Superintendent</h2>
                    <hr />
                </div>
                <div className="col-md-6">
                
          
          <img 
        //   src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1694519299895.jpeg" 
          src="../assets/images/msnew.jpg"
          className='img-fluid rounded shadow-sm'
          style={{ height: '500px', width: 'auto' }} 
          />
         <div className="text-center mt-3">
         
         </div>
                </div>
                <div className="col-md-6">
                   <div className="">
                   <br/>
                   <br/>
                   <h1>Prof (Dr.) Bhavana Shah</h1>
                    <h5>M B B S, MD </h5>
                        <p> Swaminarayan Institute of Medical Sciences and Research, Kalol.</p>
                        <p>Designation		:	Medical Superintendent</p>
                        <p>Joining Date		:      	01/08/2023</p>
                        <p>Email:  :  <a href="bcshah57@gmail.com">bcshah57@gmail.com</a></p>
                        <p>Reg. No. (UG/PG)	:	G-13306/G-3393</p>
                        <p>Qualification		:	MD (Anesthesiology)</p>
                        <p>Teaching Experience	:	36 years 9 month</p>
                        <p>Address			:	B/1 Kalash Flats Opp Amrakunj Society, Ramnagar, 
                                                Sabarmati, Ahmedabad-380005, Gujarat

                        </p>
                        <p>Phone: <a href="tel:+91-98243 15710">+91-98243 15710</a> </p>
                   </div>

                </div>
            </div>
            
        </div>
       
    </div>
  )
}

export default DeanMedicalSuperintendent