import React from 'react'
import axios from 'axios';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT_CALENDAR_GET } from '../../../Utils/InfoUploadingConst';


function EventCalendar() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  

  const getData = async () => {
      const config = {
          method: "get",
          url: EVENT_CALENDAR_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.image = JSON.parse(element.image);
              });
              console.log(res.data.data)
              setData(res.data.data);
          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  const next = async (id, data1, images) => {
      console.log("error here");
      navigate(`/Connect/WhatsHappening/EventCalendar/${id}`, {
          state: {
              images: images,
              data: data1,
          },
      });
  };

  return (
    <div className='EventCalendar mt-4'>

<div className="events-area pb-70">
  <div className="container">
    <div className="row ">
  
      <div className="row photos justify-content-center">
      {data
      ?.filter((item)=> item.college_id === 1111012)
      ?.map((item, key) => {
      return (
      item.status === "ACTIVE" &&(
        <div className="col-lg-4 col-md-6" key={item.id}>
          <div className="single-events-card style-3">
            <div className="events-image">
              {/* <a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Event+Planing.pdf">
                <img
                  className="img-fluid"
                  src="assets/images/events/event1.PNG"
                />
              </a> */}
             
              <div className="date">
                {/* <span>06</span>
                <p>Aug</p> */}
                <span>
                  {/* {item?.date?.split("-")[2]} */}
                  {item?.date ? new Date(item.date).getDate() : ''}
                </span>
                <p>{item?.month}</p>
              </div>
            </div>
            <div className="events-content">
              <div className="admin">
                <p>
                  <a href="#">
                    <i className="ri-book-3-line" /> 
                    {/* GUJARAT STATE PHARMACY COUNCIL */}
                  </a>
                </p>
              </div>
             
              {/* <h5> FREE TWO DAYS ONLINE PHARMACIST REFRESSER COURSE</h5> */}
              <a href={item?.image} target='_blank'><h5>{item?.title}</h5></a>
              
            </div>
          </div>
        </div>
      )
         )
        })
        }
      </div>
   
  
    </div>
  </div>
</div>



    </div>
  )
}

export default EventCalendar