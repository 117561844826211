import React from "react";
import "./PhdNotifications.scss";

function PhdNotifications() {
  return (
    <div className="PhdNotifications">
      <div className="description">
        <div className="container">
          <div className="register" style={{ maxWidth: "100%" }}>
            <img className="" src="assets/images/favicon.png" />
            <h3>ABOUT PH.D. PROGRAM</h3>
            <div className="row">
              <div className="col-md-12">
                <p>
                  Universities engage in research as part of their missions
                  around learning and discovery. This can come in the form of
                  fundamental or applied research. Ph.D degree typically
                  involves students independently conducting original and
                  significant research in a specific field or subject, before
                  producing a publication-worthy thesis. <br />
                  Research Policy of Swaminarayan University has adopted the
                  approach to create and support a research culture for
                  developing and promoting scientific temper and research
                  aptitudes among its teachers, staff and students. Research
                  wing of Swaminarayan University is tasked with strategic
                  academic and professional objectives. Most are highly
                  specialised and are organised into appropriate networks based
                  on the subjects they pursue.
                </p>
              </div>
              <div className="col-md-12 mt-5">
              <ul>
                  <u>
                    <h4 className=" text-danger">
                      Ph.D. 2024-25 BATCH I & II NOTIFICATIONS{" "}
                      <span className="blink text-warning"></span>
                    </h4>
                  </u>{" "}
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  Admission Result III - PhD Entrance Examination  2024-25 (Batch I)
                  
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Admission+notification+-+Phase+III.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      List of Selected Candidates in PhD Entrance Examination 2024-25 (Batch I)
                   (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  Calendar Events  2024-25 (Batch I)
                  
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Calender+Events+for+PhD+2024-25+(Batch+I)+27.7.2024.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Calendar Events  2024-25 (Batch I)
                   (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  CIRCULAR - Research paper publication
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/CIRCULAR++-+Research+paper+publication.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      CIRCULAR - Research paper publication (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  List of Selected Candidates in PhD Entrance Examination 2024-25 (Batch I)
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Admission+notification+-+Result+-+Phase+II.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      List of Selected Candidates in PhD Entrance Examination 2024-25 (Batch I) (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  Course work syllabus
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Course+work+syllabus.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Course work syllabus (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  Ph.D. Coursework Notification-July 2024-(2023-24 B-II)
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+Coursework+Notification-July+2024-(2023-24+B-II)+(1)+(1).pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Ph.D. Coursework Notification-July 2024-(2023-24 B-II)(click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  </ul>
                  <br />
                <br />
                  <div class="faq-left-content pl-20">
                  <div class="accordion mb-3" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Ph.D. 2024-25 BATCH I NOTIFICATIONS (Archived)
                        </button>
                      </h2>

                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse  "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                <ul>
                  <u>
                    <h4 className=" text-danger">
                      Ph.D. 2024-25 BATCH I NOTIFICATIONS{" "}
                      <span className="blink text-warning"></span>
                    </h4>
                  </u>{" "}


                  <br />
                  
                  <h5 className="Ph.D. text-danger text-uppercase">
                  PhD Batch 1 24-25 Selected Candidates List
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Selected+List+PhD+24-25+(Batch+1).pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      List of Provisionally Selected Candidates in PhD Entrance Examination 2024-25 (Batch I)
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  PhD Batch 1 24-25 Non Eligible List
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/PhD+Batch+1+24-25+Non+Eligible+List.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      PhD Batch 1 24-25 Non Eligible List
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}  
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger text-uppercase">
                  Syllabus for Ph.D entrance exam - Nursing only
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Syllabus+for++Ph.D+entrance+exam+-+Nursing+only.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Syllabus for Ph.D entrance exam - Nursing only
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger">
                  Ph.D. ENTERNCE TEST SYLLABUS
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/PhD+entrance+test+Syllabus.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Ph.D. Entrance Test Syllabus
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger">
                  Ph.D. ENTERNCE TEST SYLLABUS
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/PhD+entrance+test+Syllabus.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Ph.D. Entrance Test Syllabus
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="Ph.D. text-danger">
                  Ph.D. BATCH I 24-25 ELIGIBLE LIST
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/PhD+Batch+1+24-25+Eligible+list.pdf"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                      Ph.D. BATCH 1 24-25 ELIGIBLE LIST
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className=" text-danger">
                    Ph.D. 2024-25 BATCH I NOTIFICATIONS
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Revised+notification+PhD+Admission+2024-25+Batch+1.jpg"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                        Revised notification Ph.D. Admission 2024-25 Batch 1
                        (click here){" "}
                        <span className="blink text-danger"> New</span>{" "}
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                  <h5 className="text-uppercase text-danger">
                    Notification PhD Admission 2024-25 (Batch I)
                  </h5>
                  <li>
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Notification+PhD+Admission+2024-25.jpg"
                      target="_blank"
                    >
                      {" "}
                      <h6>
                        Notification Ph.D. Admission 2024-25 (Batch I) (click
                        here)
                      </h6>{" "}
                    </a>
                  </li>
                  <br />
                </ul>{" "}
                </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  
                
                <div class="faq-left-content pl-20">
                  <div class="accordion mb-3" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Ph.D. 2022-23 BATCH II NOTIFICATIONS (Archived)
                        </button>
                      </h2>

                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse  "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <u>
                              <h4 className="text-uppercase text-danger">
                                Ph.D. 2022-23 BATCH II NOTIFICATIONS{" "}
                                <span className="blink text-warning"></span>
                              </h4>
                            </u>{" "}
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Result of Ph.D. Entrance Examination 2023-24(
                              B-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+2023-24(B-II)+Entrance+Examination-+Reult+2.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. 2023-24(B-II) Entrance Examination-
                                  Result-2 (Click Here){" "}
                                  <span className="blink text-danger">
                                    {" "}
                                    {/* New */}
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Result of Ph.D. Entrance Examination 2023-24(
                              B-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D.+Entrance+Examination+2023-24(Batch-II)+Result-1.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Result of Ph.D. Entrance Examination 2023-24(
                                  B-II) (Click Here){" "}
                                  <span className="blink text-danger">
                                    {" "}
                                    {/* New */}
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Guidelines for Ph.D. Online Entrance Examination
                              2023-24( B-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Guidelines+for+Ph.D.+Online+Entrance+Examination+2023-24(+B-II).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Guidelines for Ph.D. Online Entrance
                                  Examination 2023-24( B-II) (Click Here){" "}
                                  <span className="blink text-danger">
                                    {" "}
                                    {/* New */}
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              LIST OF Ph.D. APPLICANTS ELIGIBLE FOR ENTRANCE
                              EXAMINATION-2023-24 (B-1)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/LIST%2BOF%2BPHD%2BAPPLICANTS%2BELIGIBLE%2BFOR%2BENTRANCE%2BEXAMINATION-2023-24%2B(B-1)%2B(2).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  LIST OF Ph.D. APPLICANTS ELIGIBLE FOR ENTRANCE
                                  EXAMINATION-2023-24 (B-1) (Click Here){" "}
                                  <span className="blink text-danger">
                                    {" "}
                                    {/* New */}
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Instructions for PhD Online Entrance Examination
                              2023-24 (Batch II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Instructions+for+PhD+Online+Entrance+Examination+2023-24+(Batch+II).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Instructions for PhD Online Entrance
                                  Examination 2023-24 (Batch II) (Click Here){" "}
                                  <span className="blink text-danger">
                                    {" "}
                                    {/* New */}
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              PUBLIC NOTIFICATION Ph.D.2023-24 BATCH II
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/PUBLIC+NOTIFICATION++Ph.D.2023-24+BATCH+II.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  PUBLIC NOTIFICATION Ph.D.2023-24 BATCH II
                                  (Click Here){" "}
                                  <span className="blink text-primary">
                                    {" "}
                                    {/* Closed */}
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              List of Disciplins & Subjects -Ph.D. Admission
                              (B-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/list+of+disciplins+%26+subjects+-Ph.D.+Admission+(B-II).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  List of Disciplins & Subjects -Ph.D. Admission
                                  (B-II) (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              ADMISSINON NOTIFICATION FOR PHD 2023-24 BATCH II
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/ADMISSINON+NOTIFICATION+FOR+PHD+2023-24+BATCH+II.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  ADMISSINON NOTIFICATION FOR PHD 2023-24 BATCH
                                  II (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. Course work Examination Notification,
                              2022-23 (BATCH-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+Course+work+Notification.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Course work Examination Notification,
                                  2022-23 (BATCH-II) (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <br />
                            <br />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion mb-3" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          PH.D. 2023-24 BATCH I NOTIFICATIONS (Archived)
                        </button>
                      </h2>

                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse  "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <u>
                              <h4 className="text-uppercase text-danger">
                                Ph.D. 2023-24 BATCH I NOTIFICATIONS{" "}
                                <span className="blink text-warning"></span>
                              </h4>
                            </u>{" "}
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. Provisional Admission RESULT-3, 2023-24
                              (BATCH-I)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/notification+batch+-1+PHD+2023-24.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Provisional Admission RESULT-3, 2023-24
                                  (BATCH-I){" "}
                                  <span className="blink text-danger">
                                    {" "}
                                    New
                                  </span>{" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. RESULT-2, 2023-24 (BATCH-I)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+RESULT-2%2C+2023-24+(BATCH-I).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. RESULT-2, 2023-24 (BATCH-I) (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. Admission Extension Notification 2023-24
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+Admission+Extension+Notification+2023-24.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  EXTENSION IN THE DEADLINE FOR PAYMENT OF FEES
                                  FOR PH.D. ADMISSION 2023-24 ( BATCH I) (Click
                                  Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D coursework Exam 2023-24 Time Table(Batch-I)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D+coursework+exam.jpg"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D coursework Examination 2023-24(Batch-I)
                                  Notification (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph. D. Entrance Examination 2023-24(Batch-I)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/PH.+D.+ENTRANCE+EXAMINATION+2023-24(BATCH-I)+RESULT.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph. D. Entrance Examination 2023-24(Batch-I)
                                  Notification (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D Course wise Examination 2022-23 Batch-1
                              Notification
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Scan_20230614_094650.jpg"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  {" "}
                                  Ph.D Course wise Examination 2022-23 Batch-1
                                  Notification (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D Notification Batch-1 course work exam
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D+Notification+Batch-1+course+work+exam.jpg"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  {" "}
                                  Ph.D Notification Batch-1 course work
                                  exam(Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              instructions for Ph.D online Entrance Exam 2023-24
                              (Batch-I)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/instructions%2B%2Bto%2BPh.D.%2Bcandidates+Ph.D.+2023-24+(B-1)+(2).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  {" "}
                                  Instructions for Ph.D online Entrance Exam
                                  2023-24 (Batch-I)(Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Guidelines for Ph.D online Entrance Exam 2023-24
                              (Batch-I)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Guidelines%2Bfor%2BPh.D%2Bonline%2B%2BEntrance%2BExam%2B2023-24%2B(Batch-I)+(1).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  {" "}
                                  Guidelines for Ph.D online Entrance Exam
                                  2023-24 (Batch-I)(Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              LIST OF PHD APPLICANTS ELIGIBLE FOR ENTRANCE
                              EXAMINATION-2023-24 (B-1)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/LIST+OF+PHD+APPLICANTS+ELIGIBLE+FOR+ENTRANCE+EXAMINATION-2023-24+(B-1)+(1)+(1)+(1).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  LIST OF PHD APPLICANTS ELIGIBLE FOR ENTRANCE
                                  EXAMINATION-2023-24 (B-1)(Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. 2023-24 (Batch I) Admission Extension
                              Notification{" "}
                              <span className="blink text-warning">
                                {" "}
                                Closed
                              </span>
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+Notification+2023-24.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  EXTENSION OF LAST DATE OF SUBMISSION OF ONLINE
                                  APPLICATION FORM FOR ADMISSION TO PH.D.
                                  PROGRAM 2023-24( BATCH I) (Click Here){" "}
                                  <span className="blink text-warning">
                                    {" "}
                                    Closed
                                  </span>
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. 2023-24 (Batch I) Admission Notification
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+Admission+Notification+2023-24+(+Batch+I).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. 2023-24 (Batch I) Admission Notification
                                  (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. 2023-24 (Batch I) Calendar Notification
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Scan_20230509_152109.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. 2023-24 (Batch I) Calendar of Events
                                  Notification (Click Here){" "}
                                </h6>{" "}
                              </a>
                            </li>{" "}
                            <br />
                            <br />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion mb-3" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          PH.D. 2022-23 BATCH II NOTIFICATIONS (Archived)
                        </button>
                      </h2>

                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse  "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <u>
                              <h4 className="text-uppercase text-danger">
                                Ph.D. 2022-23 BATCH II NOTIFICATIONS
                              </h4>
                            </u>{" "}
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Ph.D. Result 2, 2022-23 (Batch II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D+Result+2%2C+2022-23(Batch+II).pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Result 2, 2022-23 (Batch II)
                                  Notification (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            <h5 className="text-uppercase text-danger">
                              Extension in the deadline for payment of fees for
                              Ph.D scholars 2022-23 ( Batch II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Notification+about+Extension.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Extension in the deadline, 2022-2023(BATCH II)
                                  (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            {/* //Exam GuideLines */}
                            <h5 className="text-uppercase text-danger">
                              Ph.D Result I
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/DOC-20221216-WA0045..pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Entrance Exam Result 1, 2022-2023(BATCH
                                  II) (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            {/* //Exam GuideLines */}
                            <h5 className="text-uppercase text-danger">
                              PHD ONLINE ENTRANCE EXAM GUIDELINES 2022-23
                              (BATCH-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Guidelines+for+Ph.D+online++Entrance+Exam+2022-23+(Batch-+II)+-New.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D Online Entrance Exam Guidelines 2022-23
                                  (Batch II) (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            {/* //Exam GuideLines */}
                            <h5 className="text-uppercase text-danger">
                              PHD INSTRUCTIONS TO CANDIDATES 2022-23 (BATCH-II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/instructions++to+Ph.D.+candidates+II.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Instructions to Ph.D. candidates Batch-II.pdf
                                  (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            {/* //Revised Date Notificattion */}
                            <h5 className="text-uppercase text-danger">
                              Revised Schedule of Ph.D. entrance Examination
                              2022-23( batch II)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Phd+Revised+Notification.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Revised Schedule of Ph.D. 2022-23 (Batch II)
                                  (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            {/* //Postpone Notification */}
                            <h5 className="text-uppercase text-danger">
                              Postponement of Ph.D. 2022-23 (Batch II) Entrance
                              Examination
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/postponement+of+Ph.D.+entrance+exam.jpg"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Postponement of Ph.D. 2022-23 (Batch II)
                                  (Click Here)
                                </h6>{" "}
                              </a>
                            </li>
                            <h5 className="text-uppercase text-danger">
                              Extension Of Last Date of Submission of Online
                              Application Form for admission <br /> to Ph.D.
                              Program 2022-23( Batch II)
                              <span className="blink text-warning">CLOSED</span>
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Extension+Ph.D.+Program+2022-23(+Batch+II).jpeg"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Extension Of Ph.D. 2022-23 Batch II Admission
                                  Document
                                </h6>{" "}
                              </a>
                            </li>
                            <h5 className="text-uppercase text-danger">
                              Ph.D. 2022-23 BATCH II ADMISSION{" "}
                              <span className="blink text-warning">CLOSED</span>
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D+Admission+Notification+Batch+II+2022-23.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. 2022-23 Batch II Admission Document
                                </h6>{" "}
                              </a>
                            </li>
                            {/*<li><a href="Ph.D-Result-2.html" target="_blank">   <h6>*/}
                            {/*   Ph.D. Entrance Exam Result 2, 2022-2023(BATCH I) */}
                            {/*  </h6> </a></li>*/}
                          </ul>
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Ph.D. 2022-23 Batch II Calendar Event Notification
                              <span className="blink text-warning">CLOSED</span>
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Ph.D.+2022-23+BATCH+II+CALENDAR+EVENT+NOTIFICATION.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. 2022-2023 Batch II Notification Document
                                </h6>{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          PH.D. 2022-23 BATCH I Notifications (Archived)
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <h5 className=" text-danger">GUIDELINES</h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Guidlines.pdf#toolbar=0"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D Entrance Exam Guidelines 2022-23 (BATCH
                                  I)
                                </h6>{" "}
                              </a>
                            </li>
                          </ul>
                          <br />
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Notifications
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/notification.pdf#toolbar=0"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Extension of Last Date of Submission of Online
                                  Application Form for Admission to Ph.D Program
                                  2022-23 (BATCH I)
                                </h6>{" "}
                              </a>
                            </li>
                          </ul>
                          <ul>
                            <br />
                            <h5 className="text-uppercase text-danger">
                              Timetable
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/time-table.pdf#toolbar=0"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Entrance Exam Time Table- 2022-23 (BATCH
                                  I)
                                </h6>{" "}
                              </a>
                            </li>
                          </ul>
                          <br />
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              LIST OF PHD APPLICANTS ELIGIBLE FOR ENTRANCE
                              EXAMINATION
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D+Entrance+Eligibile+Candidatess.pdf#toolbar=0"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  List of Ph.D. Applicants Eligible for Entrance
                                  Examination, 2022-2023(BATCH I)
                                </h6>{" "}
                              </a>
                            </li>
                          </ul>
                          <br />
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Instruction to Student for Online Entrance Exam
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Instruction+updated.pdf#toolbar=0"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Instruction to Student for Online Entrance
                                  Exam, 2022-2023(BATCH I)
                                </h6>{" "}
                              </a>
                            </li>
                          </ul>
                          <br />
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Ph.D Result I
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D.+Result+1.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Entrance Exam Result 1, 2022-2023(BATCH
                                  I)
                                </h6>{" "}
                              </a>
                            </li>
                            {/*<li><a href="Ph.D-Result-2.html" target="_blank">   <h6>*/}
                            {/*   Ph.D. Entrance Exam Result 2, 2022-2023(BATCH I) */}
                            {/*  </h6> </a></li>*/}
                          </ul>
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Extension in the deadline for Payment of fees for
                              Ph.D Scholars 2022-23(Batch-1)
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Extension.pdf#toolbar=0"
                                target="_blank"
                              >
                                {" "}
                                <h6>Extension Date for Ph.D Admission</h6>{" "}
                              </a>
                            </li>
                          </ul>{" "}
                          <br />
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Ph.D Result II
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D.+Result+2.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Entrance Exam Result 2, 2022-2023(BATCH
                                  I)
                                </h6>{" "}
                              </a>
                            </li>
                            {/*<li><a href="Ph.D-Result-2.html" target="_blank">   <h6>*/}
                            {/*   Ph.D. Entrance Exam Result 2, 2022-2023(BATCH I) */}
                            {/*  </h6> </a></li>*/}
                          </ul>
                          <ul>
                            <h5 className="text-uppercase text-danger">
                              Ph.D Result III
                            </h5>
                            <li>
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Ph.D.+Result+3.pdf"
                                target="_blank"
                              >
                                {" "}
                                <h6>
                                  Ph.D. Entrance Exam Result 3, 2022-2023(BATCH
                                  I)
                                </h6>{" "}
                              </a>
                            </li>
                            {/*<li><a href="Ph.D-Result-2.html" target="_blank">   <h6>*/}
                            {/*   Ph.D. Entrance Exam Result 2, 2022-2023(BATCH I) */}
                            {/*  </h6> </a></li>*/}
                          </ul>
                          <ul></ul> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhdNotifications;
