import React from 'react'
import { Link } from 'react-router-dom'

function Ombudsperson() {
  return (
    <div>
        <div class="page-banner-area bg-1">
<div class="container">
<div class="page-banner-content">
<h1>Ombudsperson</h1>
<ul>
<li> <Link to={'./../'}>  Home </Link></li>


<li>Ombudsperson</li>
</ul>
</div>
</div>
</div>

<div className="container mt-5">
    <table className="table table-bordered text-center">
        <thead className='bg-danger text-light'>
            <tr>
                <th>Sr. No</th>
                <th>Ombudsperson</th>
                <th>Mobile No.</th>
                <th>Email-Id</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>01</td>
                <td> <h4>Dr. Baldev V. Patel </h4> 
                Formar Professor (Department of Microbilogy)<br/> Gujarat</td>
                <td><a href="tel:9824922325" className=''>+91-9824922325</a></td>
                <td><a href="mailto:patelbaldev56yahoo.in" className=''>patelbaldev56yahoo.in</a></td>

            </tr>
          
            <tr>
                <td>02</td>
                
               <td><a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Notificatio-277+Student+Grievance+redressal+Committee+(SGRC)-+Institute+Level.pdf" target="_blank" rel="noopener noreferrer">Student Grievance redressal Committee (SGRC)- Institute Level</a></td>
               <td></td>
               <td></td>

            </tr>
            <tr>
                <td>03</td>
                
               <td><a href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Notificatio-277+Student+Grievance+redressal+Committee+(SGRC)-+Institute+Level.pdf" target="_blank" rel="noopener noreferrer">Appointment as Ombudsperson for redressal of grievances of students of the university and collegesinstitutions affiliated with the university</a></td>
<td></td>
<td></td>
            </tr>
        </tbody>

    </table>
</div>
    </div>
  )
}

export default Ombudsperson