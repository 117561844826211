import React from "react";

export default function ClinicalMaterial() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Clinical Material</strong>
              </span>
            </p>

            <object
              height={1000}
              // data="/assets/ayurveda/documents/clinical-material.pdf"
              data="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1723182192228.pdf"
              type="application/pdf"
              width="100%"
            >
              <p>
                It appears you don't have a PDF plugin for this browser. No
                biggie... you can{" "}
                <a
                  // href="/assets/ayurveda/documents/clinical-material.pdf"
                  href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/media/undefined_1723182192228.pdf"
                >
                  click here to download the PDF file.
                </a>
              </p>
            </object>
          </div>
        </div>
      </div>
    </div>
  );
}
