import React from 'react';

function Registrar() {
  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-4">
            <br/>
            <img
              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/ajit-sir-b.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={350}
              height={150}
            />
            <br />
            <br />

       
          {/* <p style={{  }}>
            <strong>
            Dr. A K Gangawane
              <br />
            </strong>
              Registrar
          </p> */}
        </div>
        <div className="col-md-8">
          <h2>
            Registrar
            <br />
          </h2>
          <h4 className="text-danger text-bold">Dr. A K Gangawane</h4>
          <h6 className="text-dark">Registrar</h6>
          <p style={{ textAlign: "justify" }}>
          Dr. A K Gangawane has completed his Ph.D. in Biochemistry from Mumbai University under a DST funded project in collaboration with BARC, Mumbai and MPKV, Rahuri. He has completed M.Sc. (Biochemistry) from SIES College, Mumbai, and B.Sc. (Biochemistry &amp; Chemistry) from Somaiya College, Mumbai. He is Recipient of Department of Science and Technology, New Delhi –Fellowship (Govt. of India). Currently he is serving as Registrar, Swaminarayan University.  He has 75 full length paper, 23. Gen Bank Submission and 45 paper in international conference proceeding. Dr Gangawane has 3 textbooks and 2 book chapters to his credit and has a about 21 years’ experience in field of research teaching and academic administration. He has attended about 20 programmes related to higher education policy organized by various organization. He has a Life member for Immunology Group- ACTREC, TMC, Kharghar, and Society for Free Radical Research – INDIA (Affiliated to SFRR- Asia/International). He has guided 8 PhD students, 5 M.Tech, 8 B.Tech, 13 Msc and 3 groups of Final Year BE students.
          </p>
          <p style={{ textAlign: "justify" }}>
          Research Area: Plant Biochemistry, Molecular Biology, Antioxidants, Free radical Research, Bioinformatics and Genetic disorders.
          </p>
        </div>
      </div>
      <br />
      <br />
      
      </div>
      </>
    </div>
  )
}

export default Registrar;
