import React from 'react'

function Gallery() {
  return (
    <div>


<div className="container">
<div className="row justify-content-center align-items-center mt-5">
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g11.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g1.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g3.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g4.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g5.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g6.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g7.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g8.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g9.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g10.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g2.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="single-campus-card">
      <div className="img">
        <a href="">
          <img src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/sims/gallery/g12.jpeg" alt="Image" />
        </a>
      </div>
    </div>
  </div>

</div>

</div>

    </div>
  )
}

export default Gallery