import React from 'react'

function Advisor() {
  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-4">
            <br/>
            <img
            //   src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/ajit-sir-b.jpg"
              src="../assets/images/academics/m n patil.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={400}
              height={220}
            />
            <br />
            <br />
       
        </div>
        <div className="col-md-8">
          <h2>
          Advisor
            <br />
          </h2>
          <h4 className="text-danger text-bold">Dr. M.N. Patel</h4>
          <h6 className="text-dark">Advisor, Swaminarayan University</h6>
          <p style={{ textAlign: "justify" }}>
          Dr. M.N. Patel holds a Ph.D. in Mechanical Engineering and a Bachelor's degree in Civil Structures. With a rich academic and administrative background, he has served as the Vice Chancellor of Gujarat University for three years and as the Principal of L.D. College of Engineering in Ahmedabad, Gujarat for a decade.
          </p>
          <p style={{ textAlign: "justify" }}>
          He has also been involved in various prestigious positions such as the Member Secretary of the Admission Committee for Professional Courses in Gujarat, Vice-Chancellor (Officiating) of Gujarat Technological University, and Professor and Principal at Government Engineering College in Modasa.Dr. M.N. Patel has contributed extensively to the educational community, being a member of several governing bodies, including the Association of Indian Universities (AIU) Governing Council, the Governing Body of the Indian Institute of Management in Ahmedabad (IIMA), and the Academic Governing Body of Autonomous colleges such as St. Xavier's Science College and BVM in V.V. Nagar. He has also served on committees for inquiries, promotions, purchase, student transfers, and awards at the state and national levels. His expertise and involvement in the field extend beyond academia, as he has been a member of various professional bodies, including the Inquiry Committee by the University Grants Commission (UGC), the Staff Affairs Committee of the AIU, and the Central Regional Committee of the All India Council for Technical Education (AICTE) in Bhopal.
          </p>
          <p style={{ textAlign: "justify" }}>
          Dr. M.N. Patel has also served as the Director of the Board of Studies for Structural Engineering in G.T.U. and participated in the MSME Award Committee of the Industry Department.
          </p>
        </div>
      </div>
      <br />
      <br />
      
      </div>
      </>
    </div>
  )
}

export default Advisor;
